/**
 * Scroll to the clicked target
 * @param e - event
 * @param toTop - boolean
 * */
export const scrollTo = (e, toTop = false) => {
  e.preventDefault()

  let clickedEl = e.target

  if (e.target) {
    let target = clickedEl.getAttribute("href")
    let headerHeight = document.querySelector(".header").clientHeight

    let scrollToLoc = target
      ? document.querySelector(target).offsetTop - headerHeight
      : 0
    let sectionTarget = toTop ? 0 : scrollToLoc

    window.scrollTo({
      top: sectionTarget,
      left: 0,
      behavior: "smooth",
    })
  }
}

/**
 * Scroll to the clicked id
 * @param id - id
 * */
export const scrollToId = id => {
  if (id) {
    let target = "#" + id
    let headerHeight = document.querySelector(".header").clientHeight

    let sectionTarget = target
      ? document.querySelector(target).offsetTop - headerHeight
      : 0

    window.scrollTo({
      top: sectionTarget,
      left: 0,
      behavior: "smooth",
    })
  }
}
