import React, { useEffect, useState } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import testimonials from "../utilities/testimonials"
import lazyLoad from "../utilities/handleLazyLoad"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const ModalSimple = lazyLoad(() => import("../components/Modals/ModalSimple"))
const Video = lazyLoad(() => import("../components/Video/Video"))

/**
 * @param tabs - tab content
 * @param product - shopify product data
 * */

const PerimenoFit = ({ tabs, product }) => {
  useEffect(() => {
    testimonials(product)
  }, [])
  /*==========================
  READ MORE
  =========================*/
  const [readMore, setReadMore] = useState(false)

  /*==========================
  POPUP
  =========================*/
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const handleOpenModal = type => {
    setModalType(type)
    setIsModalOpen(true)
  }

  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="PerimenoFit - Strong and Sculpted in Perimenopause | The Betty Rocker"
        title="PerimenoFit - Strong and Sculpted in Perimenopause"
        description="Get Leaner, Stronger, Fitter, and More Powerful even while your hormones are changing!"
        url="https://store.thebettyrocker.com/products/perimenoft/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/perimenoft-fb.png"
      />

      {/*sales hero*/}
      <section className="hero bg--section pt--30 pb--30 bg--hero-perimenofit">
        <div className="container container--sxl">
          <div className="row mb--10 mb--xs-0">
            <div className="col-12 col-lg-9 col-xl-7">
              <h1 className="txt--left txt--uppercase mb--5 txt--white">PerimenoFit</h1>
              <h2 className="txt--left txt--36 mb--40 mb--xs-20 txt--white fw--medium">
                Strong and Sculpted
                <br className="d-block d-sm-none" /> in Perimenopause
              </h2>
              <div id="videoframe" className="video bg--white video--16-9 video--outline radius-5 mb--20">
                <Video url="https://player.vimeo.com/video/952891226" />
              </div>

              <p className="txt--white txt--22 fw--semibold mb--20 txt--sm-center">
                Get Leaner, Stronger, Fitter, and More Powerful even while your hormones are changing!
              </p>

              <div className="txt--left txt--sm-center">
                <a className="btn btn--lmd fw--bold btn--white txt--26" onClick={scrollTo} href="#start-now">
                  start now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="tabs--lighterpink">
        <ScrollToTabs tabs={tabs} supplements={false} />
      </div>

      <section className="container container--smd arrow-down perimenopause-section  pt--10 pt--xs-0">
        <div className="box bg--pinky p--30 p--xs-15 radius-5 mb--30 mb--xs-15">
          <p>
            <span className="txt--lighterpink fw--extrabold">Perimenopause</span> is an important transition in our
            lives and spans the 5-10 year period before our menstrual cycle stops (menopause). One of its hallmarks is
            changes in our <strong>estrogen</strong> and <strong>progesterone</strong>, both their levels with one
            another (imbalances) and their slow decline.
          </p>
          <p>
            These changes can make you feel like your body is a stranger to you, it’s not responding like it used to,
            you have more aches and pains, and starts to create a host of compounding symptoms like energy imbalance,
            weight gain, muscle loss, hot flashes, dry skin and more.
          </p>
          <img
            loading="lazy"
            src="/images/perimenofit/perimenofit-img-1-x2.jpg"
            alt="PerimenoFit."
            className="lazy radius-5 shadow-4 d-none d-sm-block"
            width="1365"
            height="360"
          />
          <img
            loading="lazy"
            src="/images/perimenofit/perimenofit-img-1-mob-x2.png"
            alt="PerimenoFit."
            className="no-shadow d-block d-sm-none"
            width="685"
            height="730"
          />
        </div>

        <div className="box bg--pinky p--30 p--xs-15 radius-5">
          <p>
            <span className="txt--lighterpink fw--bold">
              This phase of life should be one we’re talking about more, and being supported in more - but it’s very
              underserved and under-represented.
            </span>
          </p>
          <p>You deserve better. And you deserve to FEEL better.</p>
          <p>
            The more we <strong>know</strong>, the more effectively we can <strong>act</strong> to manage the hormone
            shifts, lose body fat, support our valuable muscle tissue, bone density, energy balance, and sense of self.
          </p>
          <img
            loading="lazy"
            src="/images/perimenofit/perimenofit-img-2.jpg"
            alt="PerimenoFit."
            className="pull--right radius-5 shadow-4 mb--xs-15i"
            width="260"
            height="260"
          />
          <p>
            I see <strong>Perimenopause</strong> like a rite of passage, a doorway we pass through into the next level
            of our lives as women.
          </p>
          <p>
            We’ve earned the wisdom of our years so far, and we’re going to set ourselves up in this phase of life to
            enjoy what comes next in strong, fit bodies that are capable and resilient...
          </p>

          <h3 className="txt--lighterpink txt--uppercase h3--md fw--bold txt--left mb--30 mb--sm-0">
            So let’s get PERIMENO-FIT!
          </h3>
        </div>
      </section>

      {/*how it works*/}
      <section
        className="bg--soft-gray arrow-down arrow-down--gray js-section pt--80 pt--sm-70 pt--xs-50 pb--60 pb--xs-30"
        id="how-it-works"
      >
        <div className="container">
          <h2 className="txt--center mb--15 txt--uppercase txt--sm-40 txt--xs-30 mb--xs-10">
            Here's <span className="txt--lighterpink">How It Works</span>
          </h2>
          <p className="txt--center mb--40 mb--sm-30 mb--xs-20">
            PerimenoFit is a custom 8 week strengthening and fat loss program designed for women in perimenopause.
          </p>

          <div className="row gutters-lg-10 justify-content-sm-center">
            <div className="col-12 col-sm-8 col-md-4 mb--sm-15 txt--sm-center">
              <div className="d-inline-block txt--center border-10 radius-5 border-white shadow-3 mb--10 mb--xs-5">
                <img
                  loading="lazy"
                  src="/images/perimenofit/perimenofit-hiw-1.jpg"
                  alt="PerimenoFit."
                  className="no-shadow"
                  width="350"
                  height="351"
                />
              </div>

              <a className="modal-simple-btn" data-src="#sculpt" onClick={() => handleOpenModal("sculpt")}>
                SCULPT + STRENGTHEN
              </a>
            </div>
            <div className="col-12 col-sm-8 col-md-4 mb--sm-15 txt--sm-center">
              <div className="d-inline-block txt--center border-10 radius-5 border-white shadow-3 mb--10 mb--xs-5">
                <img
                  loading="lazy"
                  src="/images/perimenofit/perimenofit-hiw-2.jpg"
                  alt="PerimenoFit."
                  className="no-shadow"
                  width="350"
                  height="351"
                />
              </div>
              <a className="modal-simple-btn" onClick={() => handleOpenModal("repair")}>
                REPAIR + SELF CARE
              </a>
            </div>
            <div className="col-12 col-sm-8 col-md-4 txt--sm-center">
              <div className="d-inline-block txt--center border-10 radius-5 border-white shadow-3 mb--10 mb--xs-5">
                <img
                  loading="lazy"
                  src="/images/perimenofit/perimenofit-hiw-3.jpg"
                  alt="PerimenoFit."
                  className="no-shadow"
                  width="350"
                  height="351"
                />
              </div>
              <a className="modal-simple-btn" onClick={() => handleOpenModal("fuel")}>
                FUEL + NOURISH
              </a>
            </div>
          </div>
        </div>
      </section>

      {/*what's included*/}
      <section className="bg--lioness-dark js-section pt--70 pt--xs-50 pb--80" id="included">
        <div className="container">
          <h2 className="txt--white mb--80 mb--sm-40 mb--xs-30 mt--10 mt--xs-0 txt--sm-40 txt--xs-30 lh--xs-1_3">
            Here’s <span className="txt--lighterpink">what’s included</span> in your 8-week
            <br className="d-none d-lg-block" /> optimized Perimenopause Training program
          </h2>

          <div className="row align-items-center row--border row--border__extended justify-content-center">
            <div className="col-12 col-lg-6 txt--center">
              <img
                loading="lazy"
                src="/images/perimenofit/perimenofit-included-1.png"
                alt="Low Impact Strength Training Workouts."
                className="no-shadow mb--md-15"
                width="570"
                height="437"
              />
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <h4 className="txt--uppercase txt--left mb--20 txt--white">
                <strong>
                  <span className="txt--lighterpink">Low Impact Strength Training Workouts:</span>
                  <br className="d-none d-md-block" /> 3 custom tracks to meet you where you’re at
                </strong>
              </h4>
              <p className="txt--white">
                Sculpt and strengthen lean muscle and improve bone density with guided workout videos that always
                include form guidance, modification options for different fitness levels and a fun follow along with
                Coach Betty Rocker!
              </p>
              <ul className="checkmark-lighterpink txt--xs txt--white">
                <li>
                  <span className="txt--lighterpink fw--extrabold">Bodyweight</span> track (uses a towel) 20-25 min
                  workouts
                </li>
                <li>
                  <span className="txt--lighterpink fw--extrabold">Home equipment</span> track (uses dumbbells, optional
                  band, optional exercise ball) 25-30 min workouts
                </li>
                <li>
                  <span className="txt--lighterpink fw--extrabold">Gym equipment</span> (uses dumbbells, barbells,
                  exercise ball, bench) 35-40 min workouts
                </li>
                <li>
                  All 3 options are available for any workout, allowing you ultimate flexibility to switch between them
                  if you like, or build from one to the next.
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border row--border__extended flex-column-reverse flex-lg-row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 txt--white">
              <h4 className="txt--uppercase mb--20 txt--white">
                <strong>
                  <span className="txt--lighterpink">HIIT Workouts:</span> Bodyweight plyometrics
                </strong>
              </h4>
              <p className="txt--white">
                Mobilize more fat in less time, and control the intensity of your workout. Form tips plus low to high
                impact options are always included.
              </p>
              <ul className="checkmark-lighterpink txt--xs txt--white">
                <li>4 minute tabata interval training to get your heart rate up in short burst cardio sessions</li>
                <li>Option to skip the HIIT video on days you want to focus more on low impact training</li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 txt--center mb--10">
              <img
                loading="lazy"
                src="/images/perimenofit/perimenofit-included-2.png"
                alt="HIIT Wokouts: Bodyweight plyometrics."
                className="no-shadow mb--md-15"
                width="532"
                height="300"
              />
            </div>
          </div>

          <div className="row align-items-center row--border row--border__extended justify-content-center">
            <div className="col-12 col-lg-6 txt--center mb--md-20">
              <img
                loading="lazy"
                src="/images/perimenofit/perimenofit-included-3.png"
                alt="Warmups and Cooldowns."
                className="no-shadow mb--md-15"
                width="398"
                height="301"
              />
            </div>
            <div className="col-12 col-md-8 col-lg-6 txt--white">
              <h4 className="txt--uppercase mb--20 txt--white">
                <strong>
                  <span className="txt--lighterpink">Warmups</span> and{" "}
                  <span className="txt--lighterpink">Cooldowns</span>
                </strong>
              </h4>
              <p className="txt--white">
                Improve your flexibility and joint health with self care around your workouts. This becomes important to
                pay attention to in perimenopause, as we lose collagen and elastin as we age.
              </p>
              <ul className="checkmark-lighterpink txt--xs txt--white">
                <li>Dynamic warmup videos for each workout to prep your muscles for maximum output. (2-4 min)</li>
                <li>Custom cooldown stretches, designed to relax warm muscle tissue and reduce soreness. (3-5 min)</li>
                <li>
                  Warmups and cooldowns are elective, and can be used or skipped depending on your time availability.
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border row--border__extended flex-column-reverse flex-lg-row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 txt--white">
              <h4 className="txt--uppercase mb--20 txt--white">
                <strong>
                  <span className="txt--lighterpink">Yoga</span> Classes and{" "}
                  <span className="txt--lighterpink">Mobility</span> Drills
                </strong>
              </h4>
              <p className="txt--white">
                Improve your body awareness and the health of your muscle tissue and joints with these fun sequences
                that will leave you feeling limber and accomplished.
              </p>
              <ul className="checkmark-lighterpink txt--xs txt--white">
                <li>
                  Guided athletic Yoga sessions to connect the body and mind, improve flexibility and use as a low
                  impact activity on a non-workout day. (20-25 min)
                </li>
                <li>
                  Guided mobility sessions to bring more awareness to our movement patterns, improve joint health and
                  use as a self care activity on a non-workout day. (20-25 min)
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 txt--center mb--md-20">
              <img
                loading="lazy"
                src="/images/perimenofit/perimenofit-included-4.png"
                alt="Yoga Classes and Mobility Drills."
                className="no-shadow mb--md-15"
                width="564"
                height="291"
              />
            </div>
          </div>

          <div className="row align-items-center mb--20 justify-content-center">
            <div className="col-12 col-lg-6 txt--center mb--10">
              <img
                loading="lazy"
                src="/images/perimenofit/perimenofit-included-5.png"
                alt="PeriMenoFit Cookbook and Eating Guide."
                className="no-shadow mb--md-15"
                width="468"
                height="322"
              />
            </div>
            <div className="col-12 col-md-8 col-lg-6 txt--white">
              <h4 className="txt--uppercase mb--20 txt--white">
                <strong>
                  PeriMenoFit <span className="txt--lighterpink">Cookbook</span> and{" "}
                  <span className="txt--lighterpink">Eating Guide</span>
                </strong>
              </h4>
              <p className="txt--white">
                Improve your gut health and support your workouts with these balanced protein-rich, high fiber recipes
                for women in Perimenopause and beyond.
              </p>
              <ul className="checkmark-lighterpink txt--xs txt--white">
                <li>
                  Eating Guide that puts the information you need to support good gut health and boost your protein
                  intake in Perimenopause, so you can apply it to your own recipes and meals for years to come!
                </li>
                <li>
                  50 tasty Recipes, including Smoothies, Breakfasts, 1-pot and 1 pan Entrees, Vegetarian entrees and
                  bonus high protein Snacks to get you on the right track!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/*bonuses*/}
      <section className="container container--md pb--70 pb--xs-40 pt--xs-30" id="bonuses">
        <h2 className="mb--40 mb--xs-20 txt--uppercase txt--sm-40 txt--xs-30">
          Special <span className="txt--lighterpink">Bonus Gifts</span>
        </h2>
        <div className="card card--tag-sm card--border-pinky mb--40 mb--xs-20">
          <div className="card__head card__head--pinky">
            <div className="tag tag--smd tag--lighterpink">
              <span className="d-none d-lg-inline-block">Bonus</span> #1
            </div>
            <h4 className="txt--uppercase txt--xs-left ml--xs-15 txt--dark-gray">
              <strong>The Perimenopause Essentials Guide</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center no-gutters">
              <div className="col-md-5 mb--sm-30 mb--xs-15 txt--center">
                <img
                  loading="lazy"
                  src="/images/perimenofit/perimenofit-bonus-1.png"
                  alt="Bonus 1."
                  className="no-shadow"
                  width="170"
                  height="191"
                />
              </div>
              <div className="col-md-7">
                <p>
                  A detailed guide to understanding what’s happening with our{" "}
                  <span className="txt--lighterpink fw--bold">hormones in perimenopause</span>, plus the{" "}
                  <strong className="fw--bold">
                    essential guide to training, nutrition, and the things you do around your workouts
                  </strong>{" "}
                  that have the biggest impact on hormone regulation and fat loss.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm card--border-pinky mb--40 mb--xs-20">
          <div className="card__head card__head--pinky">
            <div className="tag tag--smd tag--lighterpink">
              <span className="d-none d-lg-inline-block">Bonus</span> #2
            </div>
            <h4 className="txt--uppercase txt--xs-left ml--xs-15 txt--dark-gray">
              <strong>Workout Trackers</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center no-gutters">
              <div className="col-md-5 txt--center mb--sm-30 mb--xs-15">
                <img
                  loading="lazy"
                  src="/images/perimenofit/perimenofit-bonus-2.png"
                  alt="Bonus 2."
                  className="no-shadow"
                  width="182"
                  height="221"
                />
              </div>
              <div className="col-md-7">
                <p>
                  PerimenoFit is a progressive strength training program, where you’ll see the core workouts in the
                  program again so you can watch yourself progress.
                  <br className="d-none d-md-block" /> Your{" "}
                  <strong className="fw--bold">
                    printable trackers are the perfect spot to record your training sessions and{" "}
                    <span className="txt--lighterpink">follow your progress!</span>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm card--border-pinky mb--40 mb--xs-20">
          <div className="card__head card__head--pinky">
            <div className="tag tag--smd tag--lighterpink">
              <span className="d-none d-lg-inline-block">Bonus</span> #3
            </div>
            <h4 className="txt--uppercase txt--xs-left ml--xs-15 txt--dark-gray">
              <strong>Supportive Coaching Emails</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center no-gutters">
              <div className="col-md-5 txt--center mb--sm-30 mb--xs-15">
                <img
                  loading="lazy"
                  src="/images/perimenofit/perimenofit-bonus-3.png"
                  alt="Bonus 3."
                  className="no-shadow"
                  width="316"
                  height="213"
                />
              </div>
              <div className="col-md-7">
                <p>
                  While you’re following the program, you’ll{" "}
                  <strong className="fw--bold">get supportive emails from me a couple times a week</strong> to check in
                  on you and provide you with{" "}
                  <strong className="fw--bold">
                    <span className="txt--lighterpink">encouragement and information</span> about getting the most out
                    of your training!
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm card--border-pinky">
          <div className="card__head card__head--pinky">
            <div className="tag tag--smd tag--lighterpink">
              <span className="d-none d-lg-inline-block">Bonus</span> #4
            </div>
            <h4 className="txt--uppercase txt--xs-left ml--xs-15 txt--dark-gray">
              <strong>30 Days Access to My Dinner Plan</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center no-gutters">
              <div className="col-md-5 txt--center mb--sm-30 mb--xs-15">
                <img
                  loading="lazy"
                  src="/images/perimenofit/perimenofit-bonus-4.png"
                  alt="Bonus 4."
                  className="no-shadow"
                  width="340"
                  height="191"
                />
              </div>
              <div className="col-md-7">
                <p>
                  No catch or hidden charges, just a bonus access to the first month of my{" "}
                  <span className="txt--lighterpink fw--bold">popular eating program, My Dinner Plan</span> - that
                  includes healthy entrees for lunch and dinner{" "}
                  <strong className="fw--bold">
                    to help you continue to eat healthy and practice the principles you’ll learn
                  </strong>{" "}
                  in the PerimenoFit Healthy Eating and Recipes Guide!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*meet your coach*/}
      <section className="bg--soft-gray js-section" id="meet-your-coach">
        <div className="container">
          <div className="row mb--10">
            <div className="col-md-5 txt--center mb--sm-30 mb--xs-25">
              <div className="d-inline-block img--round border-5 border-lighterpink mb--15 mb--xs-20 full-width width-md-auto">
                <img
                  loading="lazy"
                  src="/images/coaches/perimenofit-round-coach.png"
                  alt="Betty Coach."
                  className="shadow-0 img--round radius- border-15 border--md-8 border-white lazy full-width width-md-auto"
                  width="433"
                  height="434"
                />
              </div>
              <h2 className="txt--24 font-lato txt--uppercase fw--black">
                Coach <span className="txt--lighterpink">Betty Rocker</span>
              </h2>
            </div>
            <div className="col-md-7 pt--120 pt--lg-50 pt--md-0">
              <h2 className="txt--sm-center txt--left mb--15 mb--xs-30">
                MEET YOUR <span className="txt--lighterpink">COACH!</span>
              </h2>

              <div className="pr--30 pr--md-0 toggle-content">
                <p className="txt--xs-left">
                  Who is Betty Rocker? Betty Rocker is me! My name is Bree Argetsinger, but I was called “Punk Rock
                  Betty Crocker” since I was a little girl - which morphed into Betty Rocker! I’m a certified trainer,
                  nutrition specialist and motivator of personal growth, and I support women all over the world of all
                  ages as they transform inside and out.
                </p>
                {readMore && (
                  <div className="mb--30 txt--xs-left">
                    <p>
                      My passion for fitness and health developed after going through many of my own health challenges,
                      inadvertently treating my body badly, and choosing to educate myself and do the work to develop
                      healthy, sustainable protocols based on science, my own intuition and a deep love and compassion
                      for other people who were struggling as well.
                    </p>
                    <p>
                      I have mastered many aspects of health and personal development, including becoming a C.H.E.K.
                      (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A.
                      certified nutrition specialist, and a Nationally Certified Structural Integration Practitioner
                      (realignment of the body through manual therapy). I have pursued continuing education to
                      incorporate training with your cycle, training in perimenopause and training post menopause in my
                      programs.
                    </p>
                    <p>
                      My goal is to empower you with the skills and knowledge you need to make the best choices for
                      yourself inside and out – so you can love the person you are, achieve the results you’re looking
                      for, and enjoy yourself along the way.
                    </p>
                  </div>
                )}

                <button
                  className={`link link--lightpink link--read-more-angle ${readMore ? "active" : ""}`}
                  onClick={() => setReadMore(!readMore)}
                >
                  Read {readMore ? "Less" : "More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*this program will help you*/}
      <section className="bg--lighterpink py--30 py--sm-30 txt--white arrow-down arrow-lighterpink">
        <div className="container">
          <h2 className="lh--xs-1_1 txt--white">
            This program will <span className="d-inline-block">help you in</span>{" "}
            <span className="d-inline-block">perimenopause by….</span>
          </h2>
        </div>
      </section>

      <div className="container pt--80 pt--xs-60 pb--60 pb--xs-40">
        <img
          loading="lazy"
          src="/images/perimenofit/perimenofit-img-3.png"
          alt="PerimenoFit."
          className="pull--right ml--45i pull-md-none d-none d-lg-block"
          width="500"
          height="506"
        />

        <ul className="checkmark-lighterpink">
          <li className="mb--15 txt--xs-20">
            ...providing you with{" "}
            <span className="txt--lighterpink fw--bold">workouts that challenge you effectively</span> so you can get
            results - without burning you out.
          </li>
          <li className="mb--15 txt--xs-20">
            …putting you <span className="txt--lighterpink fw--bold">in control of customizing your workout</span> each
            day, so you can look forward to your training and balance your energy!
          </li>
          <li className="mb--15 txt--xs-20">
            …sculpting and strengthening your muscle tissue for{" "}
            <span className="txt--lighterpink fw--bold">better body composition,</span> faster fat loss, stronger bones,
            a strong immune system, and the ability to handle all life throws your way.
          </li>
          <li className="mb--15 txt--xs-20">
            …giving you the <span className="txt--lighterpink fw--bold">nutrition guidance</span> to support your gut
            health and the effectiveness of your workouts.
          </li>
          <li className="mb--15 txt--xs-20">
            …helping you <span className="txt--lighterpink fw--bold">build stress resilience</span> with guided self
            care activities to manage cortisol levels and hormone balance.
          </li>
          <li>
            …giving you the tools to <strong className="fw--bold">support your changing hormones</strong> and{" "}
            <span className="txt--lighterpink fw--bold">transition to the NEXT LEVEL of your life</span> in a strong,
            fit body you love!
          </li>
        </ul>

        <img
          loading="lazy"
          src="/images/perimenofit/perimenofit-img-3.png"
          alt="PerimenoFit."
          className="mx--auto d-block d-lg-none"
          width="500"
          height="506"
        />
      </div>

      {/*start now*/}
      <section className="bg--soft-gray py--60 pt--xs-40 pb--xs-40" id="start-now">
        <div className="container">
          <h2 className="mb--30 mb--sm-20 mb--xs-10">START TODAY!</h2>
          <h2 className="h2--sm fw--regular txt--center mb--20 lh--xs-1_3">
            Click <span className="txt--lighterpink">“Start Now”</span> to Start PerimenoFit
          </h2>

          <div className="txt--center">
            <img
              loading="lazy"
              src="/images/perimenofit/perimenofit-main-cta.png"
              alt="PerimenoFit."
              className="no-shadow mb--20"
              width="1108"
              height="538"
            />
          </div>
          <div />

          <div className="container txt--center border-top-1 border-light-gray pt--30 pt--xs-20">
            <h2 className="fw--regular h2--xs h2--xs-32 h2--xxs-28 h2--xxxs-26 h2--xxxxs-24 mb--40 mb--xs-20 lh--xs-1_3">
              <span className="d-inline-block">
                <strong>PerimenoFit 8-week Program</strong>
              </span>{" "}
              <span className="d-inline-block">
                <span className="txt--lighterpink">($374 Value)</span> <strong>+ Bonuses </strong>
              </span>{" "}
              <span className="txt--lighterpink">($167 Value)</span>{" "}
              <span className="d-inline-block">
                <strong>= $514 Value</strong>
              </span>
            </h2>
          </div>
        </div>

        <div className="container container--md txt--center">
          <div className="pricing-box--lighterpink">
            <DigitalProductBuyBox shopifyProduct={product} />
          </div>

          <img
            loading="lazy"
            className="shadow-0 d-block mx--auto mb--30"
            src="/images/credit-cards.png"
            alt=""
            width="300"
            height="39"
          />

          <p className="txt--14 txt--xs-16 mb--10 mb--xs-20">
            <strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong>
          </p>
          <p className="txt--14 txt--xs-16">
            <em>
              You’ll receive INSTANT ACCESS to the PerimenoFit and Bonuses Immediately{" "}
              <br className="d-none d-md-block" />
              After Purchase. This Program is 100% Digital. No Physical Products will be Shipped.
            </em>
          </p>
        </div>
      </section>

      {/*guarantee*/}
      <div className="container pt--50 pt--xs-30">
        <div className="box-guarantee box-guarantee--lighterpink">
          <img className="shadow-0" src="/images/perimenofit/guarantee-100.png" alt="" width="240" height="247" />
          <div className="row p--relative">
            <div className="col-lg-8 pr--50 pr--md-0">
              <h2 className="h2--sm txt--white mb--20 txt--smd-left mt--sm-n10 mt--xs-n15 lh--xs-1_3">
                100% Money Back GUARANTEE
              </h2>

              <p className="txt--white">
                I know that this information and this program will have an incredibly positive impact on your life,
                because not only am I living it, I see it, and I read about it from the women who have experienced these
                benefits already.
              </p>
              <p className="txt--white">
                But I know you have already been on a journey getting here, and I trust that you know what’s best for
                yourself. At the end of the day, if this isn’t the right fit for you, or doesn’t create change in your
                life, don’t sweat it. You can return it within 60 days and get your money back, no questions asked.
              </p>
              <p className="txt--white txt--22 fw--extrabold">
                <span className="d-block">Bree Argetsinger</span> CEO The Betty Rocker, Inc
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*faq*/}
      <section className="js-section pt--50 pb--0 pb--xs-0" id="faq">
        <div className="container">
          <h2 className="mb--40 mb--xs-30 lh--xs-1_3">
            Frequently Asked <span className="txt--lighterpink">Questions</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs color="lighterpink faqs--md" productName="rr" />
        </div>
      </section>

      {isModalOpen && <ModalSimple type={modalType} handleCloseModal={() => setIsModalOpen(false)} />}
    </>
  )
}
export default PerimenoFit
