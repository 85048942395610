import React, { useState } from "react"

// Utilities
import lazyLoad from "../utilities/handleLazyLoad"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))

/**
 * @param tabs - tab content
 * @param product - shopify product data
 * */
const SevenDayMealPlan = ({ tabs, product }) => {
  /*==========================
  READ MORE
  =========================*/
  const [readMore, setReadMore] = useState(false)
  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="7 Day Healthy Eating Meal Plan | The Betty Rocker"
        title="7 Day Healthy Eating Meal Plan"
        description="Jumpstart your healthy eating with a week of healthy, time-saving delicious recipes (gluten and dairy free) in a done-for you 7-Day Meal Plan that will burn fat and support your active lifestyle!"
        url="https://store.thebettyrocker.com/products/7-day-meal-plan/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/7dmp-fb.jpg"
      />

      {/*sales hero*/}
      <section className="hero bg--7dmp-hero py--30">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-lg-7 order-2 order-lg-1 d-flex h--100 align-items-end justify-content-center">
              <h2 className="h2--xs h2--mob-xs h2--7dmp lh--xs-1_3">
                Boost Your Energy, <span className="d-inline-block txt--orange">Melt Fat Off Naturally,</span>{" "}
                <span className="d-inline-block">And Sculpt A Healthy FIT BODY</span>
              </h2>
            </div>
            <div className="col-12 col-sm-7 offset-sm-5 col-lg-5 offset-lg-0 order-1 order-lg-2">
              <h1 className="h1--7dmp txt--uppercase lh--1 txt--right">
                <span className="h1--xl">7 day</span>
                <span className="d-block lh--auto">healthy eating</span>
                <span className="d-inline-block d-sm-block txt--orange h1--xxl">Meal</span>{" "}
                <span className="d-inline-block d-sm-block txt--orange h1--xxxl">Plan</span>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTabs tabs={tabs} supplements={false} />

      <section className="container pt--0">
        <div className="row mb--10 mb--xs-0 justify-content-center">
          <div className="col-6 col-md-4 col-lg-3 mb--30 mb--sm-15">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-1.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">5-minute protein Pancake</div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mb--30 mb--sm-15">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-2.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">
                Chocolate <br /> protein bar
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mb--30 mb--sm-15">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-3.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">
                Red curry <br /> Pumpkin chicken{" "}
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mb--30 mb--sm-15">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-4.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">
                Tuna avocado <br />
                Salad
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mb--sm-15">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-5.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">
                Green Tempeh <br /> Curry
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mb--sm-15">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-6.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">
                Unicorn <br />
                Bowl
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mb--sm-15 mb--xs-0">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-7.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">
                pb&j green <br />
                Smoothie
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mb--sm-15 mb--xs-0">
            <div className="img-card img-card--orange">
              <img
                loading="lazy"
                className="shadow-0 lazy"
                src="/images/7dmp/food-8.jpg"
                alt=""
                width="370"
                height="411"
              />
              <div className="img-card__title txt--uppercase">
                Mixed green <br />
                salad
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--section arrow-down bg--eat-right js-section" id="how-it-works">
        <div className="container">
          <div className="d-block d-lg-none txt--center mb--30">
            <img
              loading="lazy"
              src="/images/7dmp/eat-right.jpg"
              alt=""
              className="lazy no-shadow"
              width="345"
              height="286"
            />
          </div>

          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-4">
              <h2 className="h2--sm txt--left mb--20">
                Eat Right For <span className="d-inline-block">Your LIFE.</span>
              </h2>
              <h4 className="txt--orange mb--40 h4--mob mb--xs-20">
                <strong>You don’t need some restrictive, overcomplicated diet to see results.</strong>
              </h4>

              <p>
                <strong>
                  And no eating style is going to work for you if it doesn’t fit in with how busy you are or the demands
                  of your day to day lifestyle.
                </strong>
              </p>
              <p>
                What will truly help you succeed for the long-term is{" "}
                <strong>an eating SYSTEM that flexes with YOUR life.</strong> One that gives you{" "}
                <strong>time-saving, easy</strong> and <strong>delicious</strong> meals made from whole foods, and the
                knowledge of how to combine the nutrients for a winning combination in your body – even when you’re not
                eating at home or cooking.
              </p>
              <p>
                How we eat is actually a form of COMMUNICATION we have with{" "}
                <strong>our body…and our body communicates back to us in how we look and feel.</strong>
              </p>
              <p>
                What’s at the end of your fork has more to do with how you look and feel than workouts or nice clothes
                or expensive diet pills and creams that waste your money and leave you frustrated, on an emotional
                roller coaster and dealing with cravings, erratic energy, weight gain and bloating.
              </p>
              <p>
                It’s time to take control and implement my <strong>eating-made-easy Eating Types System</strong> into
                your life with a done for you plan to follow{" "}
                <strong>so you can TELL your body that you want it to look, feel and perform it’s best</strong> BY
                FEEDING it the foods that allow it to do its job naturally.
              </p>
              <p>
                <strong>Effortlessly.</strong>
              </p>
              <p>
                And you will feel happier, more energized, lose the cravings…{" "}
                <strong>all while losing inches, turning heads and ROCKING your body AND your life.</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*what is included*/}
      <section className="arrow-down js-section" id="included">
        <div className="container container--md">
          <h2 className="h2--sm mb--40 mb--xs-30 lh--xs-1_3">
            Your Time Is Too <span className="d-inline-block">Valuable To Waste!</span>
          </h2>
          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--sm tag--orange">#1</div>
              <h4>
                <strong>
                  <span className="txt--orange">INCLUDED:</span> 7 Day Healthy Eating Meal Plan
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 mb--sm-30 txt--center">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/7dmp/included-1.png"
                    alt=""
                    width="262"
                    height="318"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-orange">
                    <li>
                      Complete guide to healthy eating that will <strong>set you up for success for life</strong>
                    </li>
                    <li>
                      Step by step guidance that allows you to <strong>enjoy life</strong> while you’re dialing in your
                      nutrition
                    </li>
                    <li>
                      <strong>Nutrition 101 Guide</strong> to make eating the right foods a no-brainer in any situation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--sm tag--orange">#2</div>
              <h4>
                <strong>
                  <span className="txt--orange">INCLUDED:</span> Recipes For The Week
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/7dmp/included-2.png"
                    alt=""
                    width="388"
                    height="337"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-orange">
                    <li>
                      Delicious <strong>fat-burning, family-friendly</strong> recipes (gluten and dairy free, plus
                      Vegetarian options)
                    </li>
                    <li>
                      <strong>Breakfast</strong> Recipes
                    </li>
                    <li>
                      Green <strong>Smoothie</strong> Recipes
                    </li>
                    <li>
                      Healthy <strong>Snacks</strong>
                    </li>
                    <li>
                      Healthy <strong>Entrees</strong> for Lunch and Dinner
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--sm tag--orange">#3</div>
              <h4>
                <strong>
                  <span className="txt--orange">INCLUDED:</span> 3 Eating Types System
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/7dmp/included-3.png"
                    alt=""
                    width="417"
                    height="415"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-orange">
                    <li>
                      Use the plan the best way that works for YOUR life and{" "}
                      <strong>have success no matter how busy you are</strong> or how little you want to cook.{" "}
                      <strong>All 3 types are included for you!</strong>
                    </li>
                    <li>
                      <strong>MINIMAL</strong> needs <strong>SHORTCUTS</strong> to save you time and EATING MADE EASY
                      that gets results – even if you are super busy, don’t cook much or are traveling.
                    </li>
                    <li>
                      <strong>HYBRID</strong> needs a <strong>FLEXIBLE</strong>, easy-to-use guide that helps you stay
                      healthy and get results on a busy schedule. You can do some light prep, but have the flexibility
                      to take healthy shortcuts.
                    </li>
                    <li>
                      <strong>BATCH</strong> needs an <strong>EFFICIENT</strong>, simple map laid out to follow so you
                      can conveniently prep your food for the week in one main session and save time all week.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--40 mb--xs-0">
            <div className="card__head card__head--gray">
              <div className="tag tag--sm tag--orange">#4</div>
              <h4>
                <strong>Done-For-You Daily Eating Guideline</strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/7dmp/included-4.png"
                    alt=""
                    width="435"
                    height="272"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-orange">
                    <li>Daily eating guidelines to help you get the BEST results</li>
                    <li>Easily combine the best fat-burning foods for energy, quality and variety</li>
                    <li>
                      Getting the results you want is easy when you combine the right foods, and you won’t be starving
                      yourself or skipping meals
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*special bonuses*/}
      <section className="bg--section arrow-down">
        <div className="container">
          <h2 className="h2--sm mb--40 mb--xs-30 lh--xs-1_3">
            <span className="txt--orange">Special Bonus Gifts</span>{" "}
            <span className="d-inline-block">
              To Help You Get <span className="d-inline-block">EVEN MORE</span>
            </span>
          </h2>

          <div className="row">
            <div className="col-12 col-md-4 mb--20">
              <div className="card card--height card--border-orange">
                <div className="tag tag--orange tag--xs">#1</div>
                <div className="card__content">
                  <div className="card__img--height border-bottom-1 border-light-gray txt--center mb--20">
                    <img
                      loading="lazy"
                      src="/images/7dmp/bonus-1.png"
                      alt=""
                      width="233"
                      height="259"
                      className="lazy no-shadow mb--20"
                    />
                  </div>
                  <h4 className="fw--medium mb--20 mb--xs-10">Fit Body Cheat Sheet</h4>
                  <p>
                    The fast-track to eating success so you can{" "}
                    <strong>be successful and reach your goals in any situation</strong>, even when you’re not cooking
                    or making your own food.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb--20">
              <div className="card card--height card--border-orange">
                <div className="tag tag--orange tag--xs">#2</div>
                <div className="card__content">
                  <div className="card__img--height border-bottom-1 border-light-gray txt--center mb--20">
                    <img
                      loading="lazy"
                      src="/images/7dmp/bonus-2.png"
                      alt=""
                      width="252"
                      height="280"
                      className="lazy no-shadow mb--20"
                    />
                  </div>
                  <h4 className="fw--medium mb--20 mb--xs-10">7-Day Coaching E-Course</h4>
                  <p>
                    <strong>Stay on track and stay motivated</strong> with this 7-Day coaching E-course to supplement
                    your program.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb--20 mb--xs-0">
              <div className="card card--height card--border-orange">
                <div className="tag tag--orange tag--xs">#3</div>
                <div className="card__content">
                  <div className="card__img--height border-bottom-1 border-light-gray txt--center mb--20">
                    <img
                      loading="lazy"
                      src="/images/7dmp/bonus-3.png"
                      alt=""
                      width="233"
                      height="259"
                      className="lazy no-shadow mb--20"
                    />
                  </div>
                  <h4 className="fw--medium mb--20 mb--xs-10">Daily Menu At A Glance</h4>
                  <p>
                    Print out your daily menu for the week to keep{" "}
                    <strong>a handy reference that helps you stay on track.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--about-betty arrow-down js-section" id="meet-your-coach">
        <div className="container">
          <div className="d-block d-lg-none txt--center mb--30">
            <img
              loading="lazy"
              src="/images/7dmp/about-betty.png"
              alt=""
              className="lazy no-shadow"
              width="303"
              height="303"
            />
          </div>
          <div className="row">
            <div className="col-12 col-lg-8 col-xl-7">
              <h2 className="h2--sm txt--left mb--40 txt--orange mb--xs-20">About Betty Rocker</h2>
              <p className="fw--black">
                <span className="txt--orange">Who is Betty Rocker?</span> Betty Rocker is me! My name is Bree
                Argetsinger, but I was called “punk rock Betty Crocker” since I was a little girl - which morphed into
                Betty Rocker! I’m a certified trainer, nutrition specialist and motivator of personal growth, and I
                support women all over the world of all ages as they transform inside and out.
              </p>
              <p>
                My passion for fitness and health developed after treating my body very badly for many years and
                choosing to educate myself and do the work to develop good practices based on science, my own intuition
                and a deep love and compassion for other people who were struggling as well.
              </p>

              {readMore && (
                <div className="mb--30">
                  <p>
                    I have mastered many aspects of health and personal development, including becoming a C.H.E.K.
                    (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A.
                    certified nutrition specialist, and a Nationally Certified Structural Integration Practitioner
                    (realignment of the body through manual therapy). I have pursued continuing education to incorporate
                    training with your cycle, training in perimenopause and training post menopause in my programs.
                  </p>
                  <p>
                    My goal is to empower you with the skills and knowledge you need to make the best choices for
                    yourself inside and out – so you can love the person you are, achieve the results you’re looking
                    for, and enjoy yourself along the way.
                  </p>
                </div>
              )}

              <button
                className={`link link--orange link--read-more ${readMore ? "active" : ""}`}
                onClick={() => setReadMore(!readMore)}
              >
                Read {readMore ? "Less" : "More"}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section id="results" className="bg--section js-section arrow-down">
        <div className="container">
          <h2 className="h2--sm mb--40 txt--capitalize lh--xs-1_3 mb--xs-30">
            How <span className="txt--orange">Betty Rocker Healthy Eating</span>{" "}
            <span className="d-inline-block">Helps You:</span>
          </h2>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb--sm-20 mb--xs-30">
              <div className="testimonial testimonial--sm-padding testimonial--shadow">
                <div className="testimonial__img m--0">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/7dmp/testimonial-1.jpg"
                    alt=""
                    width="432"
                    height="432"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb--sm-20 mb--xs-30">
              <div className="testimonial testimonial--sm-padding testimonial--shadow">
                <div className="testimonial__img m--0">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/7dmp/testimonial-2.jpg"
                    alt="."
                    width="432"
                    height="432"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb--xs-0 d-block d-sm-none d-md-block">
              <div className="testimonial testimonial--sm-padding testimonial--shadow">
                <div className="testimonial__img m--0">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/7dmp/testimonial-3.jpg"
                    alt=""
                    width="432"
                    height="432"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*fb testimonials*/}
      <section className="bg--fruit pt--xs-50">
        <div className="container">
          <div className="row align-items-center img--stack-wrapper pt--30 pt--xs-0">
            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-1.jpg"
                  alt=""
                  width="536"
                  height="138"
                />
              </div>

              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-3.jpg"
                  alt=""
                  width="541"
                  height="186"
                />
              </div>

              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-5.jpg"
                  alt=""
                  width="546"
                  height="185"
                />
              </div>

              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-7.jpg"
                  alt=""
                  width="539"
                  height="185"
                />
              </div>
            </div>

            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-2.jpg"
                  alt=""
                  width="547"
                  height="140"
                />
              </div>

              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-4.jpg"
                  alt=""
                  width="545"
                  height="97"
                />
              </div>

              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-6.jpg"
                  alt=""
                  width="537"
                  height="182"
                />
              </div>

              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/7dmp/fb-testimonial-8.jpg"
                  alt=""
                  width="544"
                  height="253"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*start now*/}
      <section className="bg--section arrow-down">
        <div className="container container--md">
          <h2 className="mb--10 h2--mob lh--xs-1_3">
            Healthy, Fit Bodies Are Made <span className="txt--orange">In The Kitchen…</span>
          </h2>
          <h2 className="h2--sm fw--regular txt--orange mb--20">Get Started Today!</h2>

          <img
            loading="lazy"
            className="lazy no-shadow"
            src="/images/7dmp/7dmp-main.png"
            alt=""
            width="1030"
            height="667"
          />
        </div>
      </section>

      {/*price + guarantee*/}
      <section className="container" id="start-now">
        <DigitalProductBuyBox shopifyProduct={product} singleBox={true} />

        <img
          loading="lazy"
          className="shadow-0 d-block mx--auto mb--30 lazy"
          src="/images/credit-cards.png"
          alt=""
          width="300"
          height="39"
        />
        <p className="txt--center txt--14 txt--xs-16 mb--10 mb--xs-20">
          <strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong>
        </p>
        <p className="txt--center txt--14 txt--xs-16">
          <em>
            You’ll receive INSTANT ACCESS to the 7-Day Meal Plan and Bonuses Immediately
            <br className="d-none d-sm-block" />
            After Purchase. This Program is 100% Digital. No Physical Products will be Shipped.
          </em>
        </p>

        <div className="box-guarantee">
          <img
            loading="lazy"
            src="/images/guarantee-100.png"
            alt=""
            width="254"
            height="261"
            className="lazy no-shadow"
          />
          <div className="row">
            <div className="col-lg-8">
              <h2 className="h2--sm txt--white mb--20 txt--smd-left lh--xs-1_3">100% Money Back GUARANTEE</h2>

              <p className="txt--white lh--auto">
                I know that this information and this System will have an incredibly positive impact on your life,
                because not only do I live it, I see it, and I read about it from the people who use it over and over.
              </p>
              <p className="txt--white lh--auto">
                But I know you have already been on a journey getting here, and I trust that you know what’s best for
                yourself. At the end of the day, if this isn’t the right fit for you, or doesn’t create change in your
                life, don’t sweat it. You can return it within 30 days and get your money back, no questions asked.
              </p>

              <p className="txt--white txt--22 fw--black">
                <span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--section js-section py--50 pb--xs-30" id="faq">
        <div className="container">
          <h2 className="mb--40 h2--mob mb--xs-20 lh--xs-1_3">
            Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs color="orange" productName="7dmp" />
        </div>
      </section>
    </>
  )
}
export default SevenDayMealPlan
