import React, { useState } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import lazyLoad from "../utilities/handleLazyLoad"

// Component
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const Video = lazyLoad(() => import("../components/Video/Video"))

/**
 * @param product - shopify product data
 * */
const MyDinnerPlan = ({ product }) => {
  const [playingVideo, setPlayingVideo] = useState(false)

  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="My Dinner Plan - The Easiest Way To Eat Healthy Meals | The Betty Rocker"
        title="My Dinner Plan - The Easiest Way To Eat Healthy Meals"
        description="Save yourself time, money and support your workouts with 6 months worth done-for-you dinner plans that contain all the right nutrients!"
        url="https://store.thebettyrocker.com/products/my-dinner-plan/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/mdp-fb.png"
      />

      {/*sales hero*/}
      <section className="hero hero-ryk pt--xs-30 bg--section bg-ryk-dinner">
        <div className="container container--mxxl">
          <div className="row no-gutters align-content-start align-items-md-center align-items-xl-start">
            <div className="col-12 col-lg-7 col-xxl-6 txt--center-md">
              <div className="hero-ryk__content">
                <img
                  loading="lazy"
                  className="shadow-0 title"
                  src="/images/ryk/my-dinner-plan-hero.svg"
                  alt="Logo"
                  width="604"
                  height="87"
                />
                <h1 className="h1--md txt--uppercase mb--15 mb--xs-10">
                  The easiest way to eat
                  <br /> <span className="txt--lighterpink txt--uppercase txt--100 lh--1">healthy meals</span>
                </h1>
                <p className="txt--22 txt--xs-18 txt--darkgray mb--25">
                  Save yourself time and money, and support your workouts with <br className="d-none d-xl-block" />
                  <strong className="fw--semibold">6 months worth</strong> done-for-you dinner plans that contain all{" "}
                  <span className="d-inline-block">the right nutrients!</span>
                </p>
              </div>

              <a
                className="btn btn--xlg mb--20 fw--medium btn--lighterpink d-none d-lg-inline-flex"
                onClick={scrollTo}
                href="#start-now"
                aria-label="Scroll to get started section."
              >
                GET STARTED
              </a>
            </div>

            <div className="col-12 col-lg-5 col-xxl-6 txt--center-md">
              <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--30">
                <Video url="https://player.vimeo.com/video/829476253" />
              </div>

              <a
                className="btn btn--xlg mb--10 fw--medium btn--lighterpink js-scroll-btn d-inline-flex d-lg-none"
                href="#start-now"
              >
                GET STARTED
              </a>

              <div className="hero-ryk--responsive">
                <img
                  loading="lazy"
                  className="shadow-0 d-block d-lg-none"
                  src="/images/ryk/plates-mobile.png"
                  alt="Dinner plan."
                  width="991"
                  height="495"
                />
              </div>
            </div>
          </div>

          <img
            loading="lazy"
            className="shadow-0 hero-ryk__img"
            src="/images/ryk/hero-img.png"
            alt="Healthy Meals."
            width="1390"
            height="235"
          />
        </div>
      </section>

      {/*what's for dinner*/}
      <section className="container container--sxl pt--50 pt--md-0 pt--sm-20 pb--80 pb--sm-30i">
        <div className="box-img">
          <div className="box-img__img">
            <img
              loading="lazy"
              className="shadow-0"
              src="/images/ryk/dinner.jpg"
              alt="Fridge Empty."
              width="564"
              height="401"
            />
          </div>

          <div className="box-img__content">
            <h3 className="txt--left mb--15 h3--md">
              <strong>What's for dinner?</strong>
            </h3>
            <p>It seems like these days it’s harder than ever to find the time to plan and prepare healthy meals.</p>
            <p>
              With overwhelming choices, rising food costs, not to mention how long it takes to figure out what to make
              for dinner for one night - let alone an entire week - people are just burned out.
            </p>
          </div>
        </div>

        <div className="box-img box-img--right">
          <div className="box-img__content">
            <h3 className="txt--left mb--15 h3--md">
              <strong>We've got you covered!</strong>
            </h3>
            <p>
              That’s why Coach Betty Rocker teamed up with professional chef Mark Galvan to create My Dinner Plan for
              you!
            </p>
            <p>
              We’ve done all your weekly meal planning <strong className="fw--semibold">for 24 straight weeks,</strong>{" "}
              provided easy-to-make tasty recipes that will support your active lifestyle and added a smart grocery list
              app that calculates all your quantities as you add things to your list so you can shop the store more
              quickly and save time and money along the way!
            </p>
          </div>

          <div className="box-img__img">
            <img
              loading="lazy"
              className="shadow-0"
              src="/images/ryk/covered.jpg"
              alt="Food."
              width="564"
              height="465"
            />
          </div>
        </div>
      </section>

      {/*make your life easier*/}
      <section className="bg--graypink pt--80 pt--sm-30 pb--0 pb--md-30">
        <div className="container container--mxxl">
          <h2 className="txt--center mb--20 lh--auto h2--mob">
            Make your life <span className="txt--lighterpink">easier</span> with a plan for
            <br className="d-none d-lg-block" /> dinner{" "}
            <span className="txt--lighterpink">
              every night of <span className="d-inline-block">the week!</span>
            </span>
          </h2>
          <p className="txt--center mb--50 mb--sm-30 txt--22 txt--xs-18 txt--darkgray">
            Enjoy balanced, chef-created dishes that are easy to make done for you for{" "}
            <strong className="fw--semibold">6 straight months!</strong> Mix and match recipes
            <br className="d-none d-xl-block" /> from the plans to make your own plans! Get all the nutrients you need
            and a variety of protein sources, including fish,
            <br className="d-none d-xl-block" /> poultry, meat and vegetarian options.
          </p>

          <div className="row justify-content-center">
            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food1.png"
                    alt="Sheet Pan Chicken."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>Sheet Pan Chicken with Tri-color Quinoa and Green Salad</p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food2.png"
                    alt="Turmeric Tofu."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>
                  <strong className="fw--extrabold txt--lighterpink">(V)</strong> Turmeric Tofu w Squash
                  <br className="d-none d-xl-block" /> and Brussel Sprouts
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food3.png"
                    alt="Turkey Burgers."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>Turkey Burgers w Sweet Potato Fries & Citrus Slaw</p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food4.png"
                    alt="Tempeh and Vegetable Stew."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>
                  <strong className="fw--extrabold txt--lighterpink">(V)</strong> Tempeh and
                  <br /> Vegetable Stew
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food5.png"
                    alt="Sheet Pan Chicken."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>
                  Cilantro Shrimp and
                  <br className="d-none d-sm-block" /> Rice Noodle Bowl
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food6.png"
                    alt="Baked BBQ Tempeh."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>
                  <strong className="txt--lighterpink fw--extrabold">(V)</strong> Baked BBQ Tempeh
                  <br className="d-none d-sm-block" /> w Sweet Potato Hash
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food7.png"
                    alt="Turkey Stir fry."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>
                  Turkey Stir Fry with Broccolini
                  <br className="d-none d-sm-block" /> and Brown Rice
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food8.png"
                    alt="Roasted Herb Tempeh."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>
                  <strong className="fw--extrabold txt--lighterpink">(V)</strong> Roasted Herb Tempeh with Artichokes
                  and New Potatoes
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food9.png"
                    alt="Honey Dijon Salmon."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>Honey Dijon Salmon with Mediterranean Chickpea Salad</p>
              </div>
            </div>

            <div className="col-6 col-md-3 col-custom-lg-5">
              <div className="circle-img-txt">
                <div className="circle-img-txt__img">
                  <img
                    loading="lazy"
                    src="/images/ryk/food10.png"
                    alt="Teriyaki Tofu."
                    width="220"
                    height="220"
                    className="lazy no-shadow"
                  />
                </div>
                <p>
                  <strong className="fw--extrabold txt--lighterpink">(V)</strong> Teriyaki Tofu w Jasmine
                  <br className="d-none d-xl-block" /> Rice and Asparagus
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*balancing your plate*/}
      <section className="bg-lightpink-halfcircle">
        <div className="container container--mxxl">
          <h2 className="txt--center mb--50 mb--sm-30 lh--auto h2--mob">
            <span className="txt--lighterpink">Balancing your plate</span> has never{" "}
            <span className="d-inline-block">been easier!</span>
          </h2>
          <div className="row gutters-20 gutters-responsive">
            <div className="col-12 col-md-4 mb--sm-30">
              <div className="icon-box">
                <div className="icon-box__wrapper">
                  <img
                    loading="lazy"
                    src="/images/ryk/gif-weekly-dinner.gif"
                    alt="Dinner."
                    width="130"
                    height="130"
                    className="shadow-0 lazy"
                  />
                </div>

                <h3 className="h3--mob">
                  <strong>Weekly Dinner Plans</strong>
                </h3>
                <p>
                  Make your life easier with a plan for dinner every night of the week!{" "}
                  <span className="txt--lighterpink">24 weeks worth of plans</span> map out complementary, tasty meals
                  to save you money (and time) shopping and provide variety to tempt your tastebuds!
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4 mb--sm-30">
              <div className="icon-box">
                <div className="icon-box__wrapper">
                  <img
                    loading="lazy"
                    src="/images/ryk/gif-recipes.gif"
                    alt="Dinner."
                    width="130"
                    height="130"
                    className="shadow-0 lazy"
                  />
                </div>

                <h3 className="h3--mob">
                  <strong>Simple Healthy Recipes</strong>
                </h3>
                <p>
                  With <span className="txt--lighterpink">over 140 recipes to choose from</span> that include a balance
                  of protein, fiber-rich carbs and healthy fats you’ll have the nutrients you need to support your
                  active lifestyle. Includes fish, meat, poultry, and vegetarian entrees.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="icon-box">
                <div className="icon-box__wrapper">
                  <img
                    loading="lazy"
                    src="/images/ryk/gif-grocery-list.gif"
                    alt="Dinner."
                    width="130"
                    height="130"
                    className="shadow-0 lazy"
                  />
                </div>

                <h3 className="h3--mob">
                  <strong>Smart Grocery Lists</strong>
                </h3>
                <p>
                  <span className="txt--lighterpink">One click adds the ingredients</span> from your recipes to your
                  smart grocery list, sorted into store sections so you can shop with ease. Create and save grocery
                  lists from meal plans, or create your own from recipes you want to make!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*how does it work*/}
      <section className="container container--sxl section-padding-xl">
        <h2 className="txt--center mb--50 lh--auto mb--sm-30 h2--mob">
          How does <span className="txt--lighterpink">My Dinner Plan</span> work?
        </h2>

        <div className="row gutters-30">
          <div className="col-12 col-md-6 d-flex align-items-center mb--80 mb--lg-30 order-2 order-md-1">
            <div className="icon-box icon-box--arrow">
              <div className="icon-box__title">
                <div className="icon-box__wrapper">
                  <img
                    loading="lazy"
                    src="/images/ryk/icon-calendar.svg"
                    alt="Icon Calendar."
                    width="29"
                    height="32"
                    className="shadow-0 lazy"
                  />
                </div>

                <h3 className="h3--mob">
                  Check out your <span className="d-inline-block">weekly plan</span>
                </h3>
              </div>

              <p className="fw--regular">
                Choose a meal plan and select regular or vegetarian entrees (each plan offers both options).{" "}
                <strong className="fw--semibold">Don’t want to follow a plan?</strong>
                <br className="d-none d-lg-block" /> No problem - go to the Recipes section and choose recipes you want
                to make!
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 mb--80 txt--center mb--lg-30 order-1 order-md-2">
            <img
              loading="lazy"
              src="/images/ryk/monitor1.png"
              alt="Screen."
              width="523"
              height="416"
              className="shadow-0 lazy"
            />
          </div>

          <div className="col-12 col-md-6 mb--80 txt--center mb--lg-30 order-3 order-md-3">
            <img
              loading="lazy"
              src="/images/ryk/monitor2-new.png"
              alt="Screen."
              width="523"
              height="416"
              className="shadow-0 lazy"
            />
          </div>

          <div className="col-12 col-md-6 d-flex align-items-center mb--80 mb--lg-30 order-4 order-md-4">
            <div className="icon-box icon-box--arrow icon-box--arrow-left">
              <div className="icon-box__title">
                <div className="icon-box__wrapper">
                  <img
                    loading="lazy"
                    src="/images/ryk/icon-cook.svg"
                    alt="Icon Cook."
                    width="30"
                    height="31"
                    className="shadow-0 lazy"
                  />
                </div>

                <h3 className="h3--mob">
                  Build Your Smart <span className="d-inline-block">Grocery List</span>
                </h3>
              </div>

              <p className="fw--regular">
                View recipes and select ingredients to add to your smart grocery list. Customize your grocery list by
                adding or removing items or changing quantities. Easily customize the servings of
                <span className="d-inline-block"> your recipes.</span>
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 d-flex align-items-center mb--80 mb--lg-30 order-6 order-md-5">
            <div className="icon-box icon-box--arrow">
              <div className="icon-box__title">
                <div className="icon-box__wrapper">
                  <img
                    loading="lazy"
                    src="/images/ryk/icon-list.svg"
                    alt="Icon Grocery."
                    width="31"
                    height="32"
                    className="shadow-0 lazy"
                  />
                </div>

                <h3 className="h3--mob">Cook and Enjoy</h3>
              </div>

              <p className="fw--regular">
                Now you’re ready to cook! Check the batch prep steps for putting all the recipes together in one
                session, or just cook as you go using the individual recipe instructions!
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 order-5 order-md-6 txt--center mb--80 mb--lg-30">
            <img
              loading="lazy"
              src="/images/ryk/monitor3.png"
              alt="Screen."
              width="523"
              height="416"
              className="shadow-0"
            />
          </div>

          <div className="col-12 col-md-6 txt--center order-7 order-md-7 mb--xs-30">
            <div className="video-wrapper">
              <img
                loading="lazy"
                src="/images/ryk/video-overlay.png"
                width="523"
                height="416"
                alt="Monitor."
                className="shadow-0"
              />
              <div className="video-ryk">
                <div className="video video--16-9">
                  <Video url="https://player.vimeo.com/video/859148612" playing={playingVideo} />

                  {!playingVideo && (
                    <div
                      id="iframe-blocker"
                      role="button"
                      aria-label="Play or pause video."
                      className="video--overlay"
                      onKeyDown={() => setPlayingVideo(!playingVideo)}
                      onClick={() => setPlayingVideo(!playingVideo)}
                      tabIndex={0}
                    >
                      <div id="play-video" className="video__txt">
                        <img
                          loading="lazy"
                          src="/images/ryk/play-video-text.svg"
                          width="136"
                          height="141"
                          alt="Play Video."
                        />
                      </div>

                      <div id="play-button" className="video__icon">
                        <img loading="lazy" src="/images/ryk/icon-play.svg" width="30" height="36" alt="Play icon." />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 d-flex align-items-center order-8 order-md-8">
            <div className="icon-box icon-box--arrow">
              <div className="icon-box__title">
                <div className="icon-box__wrapper">
                  <img
                    loading="lazy"
                    src="/images/ryk/icon-quotes.svg"
                    alt="Icon Quotes."
                    width="22"
                    height="17"
                    className="shadow-0 lazy"
                  />
                </div>

                <h3 className="h3--mob">
                  Hear how people <span className="d-inline-block">like using it!</span>
                </h3>
              </div>

              <p className="fw--regular">
                Trista has been using My Dinner Plan for a few months, and is sharing how it’s working for her, plus
                going over some of its features.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*what's included*/}
      <section className="bg--graypink section-padding-xl">
        <div className="container container--mmd">
          <h2 className="mb--50 mb--sm-30 lh--auto h2--mob">
            What’s included in <span className="txt--lighterpink d-inline-block">My Dinner Plan?</span>
          </h2>
          <div className="card-wrapper">
            <div className="card card--tag-sm card--price mb--30 mb--xs-20">
              <div className="card__head card__head--lighterpink card__head--price">
                <div className="tag tag--white tag--square">#1</div>
                <h3 className="h3--md txt--uppercase">
                  <strong>
                    <span className="tag--reposnive">INCLUDED:</span>
                    <br className="d-block d-md-none" /> Meal Plans for 6 Months
                  </strong>
                </h3>
                <h3 className="h3--md">
                  <strong className="fw--medium">$247 Value</strong>
                </h3>
              </div>
              <div className="card__content">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1">
                    <p>Make your life easier with a plan for dinner every night of the week!</p>
                    <p>
                      You'll find 24 weeks worth of plans for MON-SUN with the exact recipes to make, and the food prep
                      steps needed in case you'd like to batch prep.
                    </p>
                    <p>
                      Easily customize the serving size based on how many people you’re cooking for with the click of a
                      button, add ingredients to your smart grocery list from within the recipe, and reference the easy
                      prep steps to put it all together!
                    </p>
                  </div>

                  <div className="col-md-6 mb--sm-20 txt--center order-1 order-md-2">
                    <img
                      loading="lazy"
                      className="shadow-0 mw--md-none lazy"
                      alt="What's included."
                      width="460"
                      height="330"
                      src="/images/ryk/included1.png"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card card--tag-sm card--price mb--30 mb--xs-20">
              <div className="card__head card__head--lighterpink card__head--price">
                <div className="tag tag--white tag--square">#2</div>
                <h3 className="h3--md txt--uppercase">
                  <strong>
                    <span className="tag--reposnive">INCLUDED:</span>
                    <br className="d-block d-md-none" /> Healthy Recipes Library
                  </strong>
                </h3>
                <h3 className="h3--md">
                  <strong className="fw--medium">$147 Value</strong>
                </h3>
              </div>
              <div className="card__content">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1">
                    <p>
                      Your recipe collection includes a wide variety of simple, nourishing (gluten free and dairy free)
                      recipes with protein sources like fish, meat, poultry and Vegetarian options.
                    </p>
                    <p>
                      Easily customize the amount of servings you want to make with a single click, and add ingredients
                      straight to your buildable grocery list from within your recipe.
                    </p>
                    <p>Your recipes are printable and include nutrition information.</p>
                  </div>

                  <div className="col-md-6 txt--center order-1 order-md-2">
                    <img
                      loading="lazy"
                      className="shadow-0 mw--md-none lazy"
                      alt="What's included."
                      width="460"
                      height="330"
                      src="/images/ryk/included2.png"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card card--tag-sm card--price mb--30 mb--xs-20">
              <div className="card__head card__head--lighterpink card__head--price">
                <div className="tag tag--white tag--square">#3</div>
                <h3 className="h3--md txt--uppercase">
                  <strong>
                    <span className="tag--reposnive">INCLUDED:</span>
                    <br className="d-block d-md-none" /> Save Your Favorites
                  </strong>
                </h3>
                <h3 className="h3--md">
                  <strong className="fw--medium">$37 Value</strong>
                </h3>
              </div>
              <div className="card__content">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1">
                    <p>You can save your favorite recipes or weekly meal plans for reference anytime!</p>
                    <p>
                      Want to make your own plan for the week from recipes you found in the Recipe Library? Just save
                      them to your favorites and find them with ease!
                    </p>
                  </div>

                  <div className="col-md-6 mb--sm-20 txt--center order-1 order-md-2">
                    <img
                      loading="lazy"
                      className="shadow-0 mw--md-none lazy"
                      alt="What's included."
                      width="460"
                      height="213"
                      src="/images/ryk/included3.png"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card card--tag-sm card--price mb--30 mb--xs-20">
              <div className="card__head card__head--lighterpink card__head--price">
                <div className="tag tag--white tag--square">#4</div>
                <h3 className="h3--md txt--uppercase">
                  <strong>
                    <span className="tag--reposnive">INCLUDED:</span>
                    <br className="d-block d-md-none" /> Smart Grocery Lists
                  </strong>
                </h3>
                <h3 className="h3--md">
                  <strong className="fw--medium">$97 Value</strong>
                </h3>
              </div>
              <div className="card__content">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1">
                    <p>
                      Your grocery lists are built directly from your recipes by selecting the ingredients you want. My
                      Dinner Plan calculates the quantities for you based on how many servings you want to make, and
                      adds them by section to your list, making it easy to shop the store by category.
                    </p>
                    <p>
                      You can create as many grocery lists as you like, add custom items you don’t want to forget. Save
                      and name your grocery lists for future reference, print them out as needed and even email them to
                      yourself or whoever is going shopping!
                    </p>
                  </div>

                  <div className="col-md-6 mb--sm-20 txt--center order-1 order-md-2">
                    <img
                      loading="lazy"
                      className="shadow-0 mw--md-none lazy"
                      alt="What's included."
                      width="460"
                      height="330"
                      src="/images/ryk/included4.png"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card card--tag-sm card--price">
              <div className="card__head card__head--lighterpink card__head--price">
                <div className="tag tag--white tag--square">#5</div>
                <h3 className="h3--md txt--uppercase">
                  <strong>
                    <span className="tag--reposnive">INCLUDED:</span>
                    <br className="d-block d-md-none" /> Kitchen Pro Video Series
                  </strong>
                </h3>
                <h3 className="h3--md">
                  <strong className="fw--medium">$167 Value</strong>
                </h3>
              </div>
              <div className="card__content">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1">
                    <p>
                      Join Coach Betty Rocker and Chef Mark for a series of cooking skills classNamees to save you time,
                      help you master tips the pros use, and boost your confidence in cooking these tasty, delicious
                      meals!
                    </p>
                  </div>

                  <div className="col-md-6 mb--sm-20 txt--center order-1 order-md-2">
                    <img
                      loading="lazy"
                      className="shadow-0 mw--md-none lazy"
                      alt="What's included."
                      width="460"
                      height="213"
                      src="/images/ryk/included5.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*make my life easier*/}
      <section className="section-padding-xl">
        <div className="container container--sxl">
          <h2 className="mb--50 mb--sm-30 mb--xs-20 lh--auto h2--mob">
            How can <span className="txt--lighterpink">My Dinner Plan</span> make
            <br className="d-none d-md-block" /> my <span className="d-inline-block">life easier?</span>
          </h2>

          <div className="row no-gutters gutters-lg-10 align-items-center">
            <div className="col-12 col-md-5 mb--50 order-1 txt--center txt--md-left mb--md-30 mb--xs-20">
              <img
                loading="lazy"
                src="/images/ryk/img1.jpg"
                alt="Save money."
                width="433"
                height="281"
                className="lazy"
              />
            </div>

            <div className="col-12 col-md-7 mb--50 order-2 mb--md-30">
              <h4 className="txt--lighterpink mb--15 txt--capitalize txt--24">
                <strong className="fw--medium">You’ll save money</strong>
              </h4>
              <p className="txt--18 txt--darkgray">
                With rising food costs, finding ways to save money on food is essential.
                <br className="d-none d-lg-block" /> With <strong className="fw--semibold">My Dinner Plan</strong>{" "}
                you’ll have tasty and varied dishes throughout the week with crossover ingredients so you aren’t buying
                tons of costly, specialty ingredients.
              </p>
              <p className="txt--18 txt--darkgray">
                You can say goodbye to food waste (another money-saver) and avoid being stuck with nothing to eat at the
                end of a busy day and feeling like your only option is to order takeout. Enjoy putting more nutrients in
                your body, and more money back in your pocket!
              </p>
            </div>

            <div className="col-12 col-md-7 mb--50 mb--md-30 order-4 order-md-3">
              <h4 className="txt--lighterpink mb--15 txt--capitalize txt--24">
                <strong className="fw--medium">You’ll save Time</strong>
              </h4>
              <p className="txt--18 txt--darkgray">
                Figuring out what to eat every night means taking time to research recipes, finding recipes that share
                complimentary ingredients, building a grocery list and calculate quantities for the number of people
                you’re cooking for.
              </p>
              <p className="txt--18 txt--darkgray">
                With all the rest of the things you have going on every day, let us save you the time and effort this
                takes by giving you access to thoughtful, nourishing meals and
                <br className="d-none d-lg-block" /> recipes that you can add to your grocery list with the click of a
                button.
              </p>
            </div>

            <div className="col-12 col-md-5 mb--50 mb--md-30 mb--xs-20 txt--center txt--md-right order-3 order-md-4">
              <img
                loading="lazy"
                src="/images/ryk/img2.jpg"
                alt="Save time."
                width="433"
                height="281"
                className="lazy"
              />
            </div>

            <div className="col-12 col-md-5 mb--50 mb--md-30 mb--xs-20 order-5 txt--center txt--md-left">
              <img
                loading="lazy"
                src="/images/ryk/img3.jpg"
                alt="Better food."
                width="433"
                height="281"
                className="lazy"
              />
            </div>

            <div className="col-12 col-md-7 mb--50 mb--md-30 order-6">
              <h4 className="txt--lighterpink mb--15 txt--capitalize txt--24">
                <strong className="fw--medium">You’ll Eat Better Food</strong>
              </h4>
              <p className="txt--18 txt--darkgray">
                Without a plan, it’s easy to fall into just going out to eat or ordering takeout. Having home-cooked
                meals means the nutrients you need are guaranteed to be in the meals you’re eating - without any extras
                or additives, hidden sugars or oils you didn’t realize things were cooked in.
              </p>
              <p className="txt--18 txt--darkgray">
                When you have tasty, nourishing meals to eat at home, you’ll fuel up when you’re hungry - not when
                you’re hangry, and be less likely to overeat or rely on junk and snacks and sweets.
              </p>
            </div>

            <div className="col-12 col-md-7 order-8 order-md-7">
              <h4 className="txt--lighterpink mb--15 txt--capitalize txt--24">
                <strong className="fw--medium">You’ll Achieve and maintain a healthy physique</strong>
              </h4>
              <p className="txt--18 txt--darkgray">
                There’s no question that exercise and nutrition go hand in hand. Without quality nutrients you simply
                won’t see the results of your active lifestyle.
              </p>
              <p className="txt--18 txt--darkgray">
                Having consistent and healthy high-quality meals that contain the right balance of protein, carbs,
                healthy fats and greens will unlock your potential to achieve your fittest physique, improve your energy
                and boost your overall health!
              </p>
            </div>

            <div className="col-12 col-md-5 txt--center txt--md-right order-7 order-md-8 mb--md-30 mb--xs-20">
              <img loading="lazy" src="/images/ryk/img4.jpg" alt="Healthy." width="433" height="281" className="lazy" />
            </div>
          </div>
        </div>
      </section>

      {/*what our customers are saying*/}
      <section className="bg--pinky">
        <div className="container">
          <h2 className="mb--40 mb--sm-30 h2--mob">
            What our <span className="txt--lighterpink">customers</span>{" "}
            <span className="d-inline-block">are saying….</span>
          </h2>

          <div className="row align-items-center img--stack-wrapper">
            <div className="col-sm-6 mb--50 mb--sm-30 mb--xs-30">
              <div className="img-stack img-stack--transparent">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/ryk/fb-testimonial-1.jpg?v=1.0.0"
                  alt="Testimonial."
                  width="544"
                  height="584"
                />
              </div>
            </div>
            <div className="col-sm-6 mb--50 mb--sm-30 mb--xs-30">
              <div className="img-stack img-stack--transparent">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/ryk/fb-testimonial-2.jpg?v=1.0.0"
                  alt="Testimonial."
                  width="544"
                  height="584"
                />
              </div>
            </div>
            <div className="col-sm-6 mb--50 mb--sm-30 mb--xs-30">
              <div className="img-stack img-stack--transparent">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/ryk/fb-testimonial-angie.png"
                  alt="Testimonial."
                  width="544"
                  height="127"
                />
              </div>
            </div>
            <div className="col-sm-6 mb--50 mb--sm-30 mb--sm-20">
              <div className="img-stack img-stack--transparent">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/ryk/fb-testimonial-colleen.png"
                  alt="Testimonial."
                  width="544"
                  height="127"
                />
              </div>
            </div>
            <div className="col-sm-6 mb--50 mb--sm-30 mb--sm-20">
              <div className="img-stack img-stack--transparent">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/ryk/fb-testimonial-3.jpg?v=1.0.0"
                  alt="Testimonial."
                  width="544"
                  height="584"
                />
              </div>
            </div>
            <div className="col-sm-6 mb--50 mb--sm-20 mb--xs-20">
              <div className="img-stack img-stack--transparent">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/ryk/fb-testimonial-4.jpg?v=1.0.0"
                  alt="Testimonial."
                  width="544"
                  height="584"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*start my dinner plan today*/}
      <section className="bg--graygradient pt--80 pt--sm-30 pb--60 pb--sm-0">
        <div className="container container--mxxl">
          <h2 className="mb--20 h2--mob lh--xs-1_3">
            Start <span className="txt--lighterpink">My Dinner Plan</span> Today...
          </h2>
          <p className="txt--22 txt--darkgray txt--center mb--50 mb--sm-30">
            Save time and money while enjoying delicious, healthy meals with all the planning done for you!
          </p>

          <img
            loading="lazy"
            className="lazy mx--auto d-none d-md-block shadow-0 mb--80 mb--md-30"
            alt="What you get."
            width="1241"
            height="735"
            src="/images/ryk/wyg.png"
          />
          <img
            loading="lazy"
            className="lazy mx--auto d-block d-md-none shadow-0 mb--80 mb--md-40"
            alt="What you get."
            width="767"
            height="1203"
            src="/images/ryk/wyg-mob.png"
          />

          <h2 className="mb--40 mb--sm-30 d-flex flex-column flex-md-row align-items-center justify-content-center h2--mob lh--xs-1_3">
            <span className="txt--lighterpink mr--20 mr--sm-0">My Dinner Plan</span>{" "}
            <span className="fw--medium h2 txt--xs-28">(Total value: $695)</span>
          </h2>

          <div className="row gutters-30 gutters-responsive">
            <div className="col-12 col-md-4 mb--sm-30">
              <div className="icon-box">
                <img
                  loading="lazy"
                  src="/images/ryk/icon-cooking-hat.svg"
                  alt="Cooking Hat."
                  width="95"
                  height="80"
                  className="shadow-0 lazy mb--30"
                />

                <p className="txt--darkgray txt--18 fw--regular">
                  Hiring a professional chef to do your planning and prep costs between{" "}
                  <strong className="fw--semibold">$300-$500 a week</strong> (not including the cost of groceries).
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4 mb--sm-30">
              <div className="icon-box">
                <img
                  loading="lazy"
                  src="/images/ryk/icon-money.svg"
                  alt="Icon Handing Money."
                  width="80"
                  height="80"
                  className="shadow-0 lazy mb--30"
                />

                <p className="txt--darkgray txt--18 fw--regular">
                  Combining the cost of each component of this 24-week plan would cost $695, which averages out to{" "}
                  <strong className="fw--semibold">$29 a week</strong> to have everything planned and at your
                  fingertips.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="icon-box">
                <img
                  loading="lazy"
                  src="/images/ryk/icon-party-hat.svg"
                  alt="Icon Party Head."
                  width="81"
                  height="80"
                  className="shadow-0 lazy mb--30"
                />

                <p className="txt--darkgray txt--18 fw--regular">
                  But Betty Rocker fam can get all 6 months worth of My Dinner Plan for $197, more than half off the
                  average it would cost for a chef for a week, and for a fraction of the total value of the components{" "}
                  <strong className="fw--semibold">- making it just $8 a week!</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*pricing*/}
      <section id="start-now" className="bg--pricing">
        <div className="container container--xl txt--center">
          <DigitalProductBuyBox shopifyProduct={product} variantBox={true} />

          <img
            loading="lazy"
            className="shadow-0 d-block mx--auto mb--15 lazy"
            src="/images/credit-cards.png"
            alt="Credit Cards."
            width="300"
            height="39"
          />

          <p className="txt--14 txt--xs-16 mb--10 mb--xs-20">
            <strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong>
          </p>
          <p className="txt--14 txt--xs-16 mb--md-15">
            <em>
              You’ll receive INSTANT ACCESS to the My Dinner Plan and Bonuses Immediately After Purchase.
              <br className="d-none d-sm-block" /> This Program is 100% Digital. No Physical Products will be Shipped.
            </em>
          </p>

          <img
            loading="lazy"
            className="shadow-0 d-block d-lg-none mx--auto lazy responsive-img"
            src="/images/ryk/pricing-img-mob.png"
            alt="My Dinner Plan."
            width="991"
            height="652"
          />
        </div>
      </section>

      {/*guarantee*/}
      <section className="section-ryk-guarantee">
        <div className="container container--sxl">
          <div className="box-guarantee box-guarantee--ryk">
            <div className="row no-gutters">
              <div className="col-12 col-md-7 col-lg-6 col-xl-8">
                <h2 className="h2--sm mb--20 txt--left txt--sm-center lh--xs-1_3 txt--xs-24i mb--xs-15">
                  No Risk 30-Day <span className="txt--lighterpink">Money Back GUARANTEE</span>
                </h2>
                <h5 className="mb--20 txt--xs-18">
                  Check out <span className="txt--lighterpink">My Dinner Plan</span> and experience the benefits for
                  yourself…
                </h5>
                <p>
                  I know that this resource will have an incredibly positive impact on your life, because not only do I
                  live it, I see it, and I read about it from the people who use it over and over.
                </p>

                <p>
                  But I know you have already been on a journey getting here, and I trust that you know what’s best for
                  yourself. At the end of the day, if this isn’t the right fit for you, or doesn’t create change in your
                  life, don’t sweat it. You can return it within 30 days and get your money back, no questions asked.
                </p>

                <p className="fw--medium txt--22">
                  <span className="txt--uppercase txt--lighterpink fw--extrabold">Bree Argetsinger</span>
                  <br /> CEO The Betty Rocker, Inc
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*faq*/}
      <section className="bg--graygradient section-padding-xl js-section" id="faq">
        <div className="container">
          <h2 className="mb--40 h2--mob mb--xs-30">
            Frequently Asked <span className="txt--lighterpink">Questions</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs productName="mdp" color="lighterpink" />
        </div>
      </section>
    </>
  )
}
export default MyDinnerPlan
