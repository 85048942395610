import React, { useEffect } from "react"

// Utilities
import lazyLoad from "../utilities/handleLazyLoad"

// Data
import FULL_PRODUCT_DATA from "../../static/data/full-product"

// Product Content - Meal Plans & Workout Plans
import SevenDayMealPlan from "../products/7-day-meal-plan"
import ThirtyDayMealPlan from "../products/30-day-meal-plan"
import NinetyDayChallenge from "../products/90-day-challenge"
import BodyFuelSystem from "../products/body-fuel-system"
import HotAbsAndBooty from "../products/30-day-booty-abs-challenge"
import HomeWorkoutDomination from "../products/home-workout-domination"
import HomeWorkoutDomination2 from "../products/home-workout-domination-2"
import Lioness from "../products/lioness"
import PerimenoFit from "../products/perimenofit"
import MyDinnerPlan from "../products/my-dinner-plan"

const Components = {
  "gid://shopify/Product/6667192467648": SevenDayMealPlan,
  "gid://shopify/Product/6667428495552": ThirtyDayMealPlan,
  "gid://shopify/Product/7492241817792": MyDinnerPlan,
  "gid://shopify/Product/6667468341440": NinetyDayChallenge,
  "gid://shopify/Product/6667451465920": BodyFuelSystem,
  "gid://shopify/Product/6667486855360": HotAbsAndBooty,
  "gid://shopify/Product/6667479253184": HomeWorkoutDomination,
  "gid://shopify/Product/7282410389696": HomeWorkoutDomination2,
  "gid://shopify/Product/6667482759360": Lioness,
  "gid://shopify/Product/7928701649088": PerimenoFit,
}

// Components
const ScrollTopButton = lazyLoad(() => import("../components/Scrollable/ScrollTopButton"))
const SuggestedProducts = lazyLoad(() => import("../components/SuggestedProducts/SuggestedProducts"))

const ProductTemplate = ({ pageContext }) => {
  const { product } = pageContext

  const shopifyProductId = product.shopifyId
  const currentProduct = FULL_PRODUCT_DATA[shopifyProductId]

  const Component = Components[shopifyProductId]

  /*============================
    CUSTOM GTM EVENT
  =============================*/
  useEffect(() => {
    let items = [
      {
        item_id: shopifyProductId,
        item_name: product.title,
        currency: "USD",
        item_brand: "The Betty Rocker",
        item_category: currentProduct.type,
        price: +product.variants[0].price,
        quantity: 1,
      },
    ]

    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          item_list_name: currentProduct.type,
          items: items,
        },
      })
    }
  }, [])

  return (
    <>
      {currentProduct && (
        <>
          <Component key={shopifyProductId} tabs={currentProduct.tabs} product={product} />

          {/*scrollTop**/}
          <ScrollTopButton isSupplement={false} isButtonOnly={!currentProduct.tabs.length} tabs={currentProduct.tabs} />

          {/*Suggested Products*/}
          <SuggestedProducts currentItemId={shopifyProductId} imgOrientation={"horizontal"} isCart={false} />
        </>
      )}
    </>
  )
}
export default ProductTemplate
