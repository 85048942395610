import React, { useEffect, useState } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import testimonials from "../utilities/testimonials"
import lazyLoad from "../utilities/handleLazyLoad"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const ModalCoach = lazyLoad(() => import("../components/Modals/ModalCoach"))
const Video = lazyLoad(() => import("../components/Video/Video"))

/**
 * @param tabs - tab content
 * @param product - shopify product data
 * */
const HomeWorkoutDomination2 = ({ tabs, product }) => {
  /*====================
    COACH MODAL
   =======================*/
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [coachName, setCoachName] = useState("")

  const handleModal = name => {
    setCoachName(name)
    setIsOpenModal(true)
  }

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isOpenModal) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isOpenModal])

  useEffect(() => {
    testimonials(product)
  }, [])

  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="Home Workout Domination 2 | The Betty Rocker"
        title="Home Workout Domination 2"
        description="Continue the Home Workout Domination journey with fun, effective workouts you can do from home!"
        url="https://store.thebettyrocker.com/products/home-workout-domination-2/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/hwd2-fb.jpg"
      />

      {/*sales hero*/}
      <section className="hero bg--section py--30 pt--xs-30 pb--xs-30 bg--hero-hwd2">
        <div className="bg--hero-hwd2-mob d-flex justify-content-start align-items-center d-lg-none">
          <h1 className="txt--left h1--smxs mt--10">
            Home Workout <br />
            <span className="big-title">Domination </span>
          </h1>
          <h1>
            <span className="title-number">2</span>
          </h1>
        </div>

        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-9 col-xl-8">
                <h1 className="d-none d-lg-flex align-items-center txt--left mb--20">
                  Home Workout Domination <span className="txt--purple-light txt--100 ml--10">2</span>
                </h1>
                <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--20">
                  <Video url="https://player.vimeo.com/video/716580684" />
                </div>
                <p className="txt--center txt--25 txt--xs-20 txt--xxs-18">
                  <strong>
                    <span className="txt--purple-light txt--xs-darkgray">Continue the </span>
                    <span className="txt--xs-purple-light txt--darkgray">Home Workout Domination </span>

                    <span className="txt--purple-light txt--xs-darkgray">
                      {" "}
                      journey <br className="d-none d-md-block" /> with fun, effective workouts you can do{" "}
                      <span className="d-inline-block">from home !</span>
                    </span>
                  </strong>
                </p>

                <div className="txt--center md--down">
                  <a className="btn btn--md fw--medium" onClick={scrollTo} href="#start-now">
                    start now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTabs tabs={tabs} supplements={false} />

      {/*how it works*/}
      <section className="container arrow-down js-section pt--xs-20" id="how-it-works">
        <div className="container">
          <h2 className="h2--sm mb--40 txt--xs-24i fw--xs-meduim mb--xs-20">
            <span className="txt--purple-light">Home Workout Domination 2</span> is the perfect next step after{" "}
            <br className="d-none d-lg-block" />
            Home Workout Domination so <span className="d-inline-block">you can…</span>
          </h2>

          <div className="row align-items-center">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
              <img
                loading="lazy"
                className="lazy no-shadow mb--md-15"
                src="/images/hwd2/hwd2-calendars2.png"
                alt="Home Workout Domination 2."
                width="541"
                height="388"
              />
            </div>

            <div className="col-12 col-md-6">
              <ul className="checkmark-purple-light">
                <li>Continue to progress with all new workouts including both strength training and plyometrics.</li>
                <li>
                  Improve body composition (sculpt lean muscle and trigger fat loss) with these effective and fun
                  workouts
                </li>
                <li>Get great results with time saving workouts</li>
                <li>
                  Workout at home or in the gym with simple equipment like dumbbells, bands and an exercise ball (other
                  options shown)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="arrow-down bg--section js-section" id="results">
        <div className="container">
          <h2 className="h2--sm mb--20 lh--xs-1_3">
            Betty Rocker <span className="d-inline-block txt--purple-light">Program Results:</span>
          </h2>

          <p className="txt--center mb--40 mb--xs-30">
            We can’t wait to see how you do, and we know you’ll crush it! <br className="d-none d-md-block" />
            Here are some examples of the results people have gotten from programs like this one!
          </p>
        </div>
        <div className="container container--md">
          <div className="row">
            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hwd2/testimonial-1.jpg"
                    alt="Testimonial of Jenna B."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I'm super stoked on how much I've achieved!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I'm super stoked on how much I've achieved! I did a lot of cardio (biking) before this and would
                      loose weight but wasn't getting toned or seeing the results I wanted to see. Thank you Bree and
                      all the Betty Rocker coaches!
                    </p>
                    <p className="txt--pink">
                      <strong>- Jenna B.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hwd2/testimonial-2.jpg"
                    alt="Testimonial of Krystal R."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Have lost 30 lbs and many inches!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I started my seventh 30 Day Challenge yesterday and have seen significant results since I started
                      last April. I'm loving my body, feeling amazing, have lost 30 lbs and many inches!
                    </p>
                    <p className="txt--pink">
                      <strong>- Krystal R.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hwd2/testimonial-3.jpg"
                    alt="Testimonial of Veronica S."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"With your workouts I have lost 24 pounds!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      With your workouts I have lost 24 pounds! Thank you for all the great information, resources and
                      awesome work outs! Also purchased protein powders and they are delicious!! Here is my before and
                      after… now I'd like to work on my core and gain strength!
                    </p>
                    <p className="txt--pink">
                      <strong>- Veronica S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hwd2/testimonial-4.jpg"
                    alt="Testimonial of Denise S."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Betty Rocker you have changed my life!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      Took some more progress pictures and I am so pleased! I never thought I could have muscle def
                      again at almost 50 years old! Betty Rocker, you have changed my life!
                    </p>
                    <p className="txt--pink">
                      <strong>- Denise S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hwd2/testimonial-5.jpg"
                    alt="Testimonial of Ashley B."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Thank you for teaching me so much!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I just want to let Betty Rocker and the other fabulous coaches know 1) how much I appreciate that
                      you do the whole workout with us, 2) you are real people who get tired and have good days and
                      better days like me, 3) you are doing these workouts in your homes without a bunch of fancy
                      equipment. All this helps me know I can do this! Thank you for teaching me so much, keeping me
                      motivated, and making it fun!
                    </p>
                    <p className="txt--pink">
                      <strong>- Ashley B.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hwd2/testimonial-6.jpg"
                    alt="Testimonial of Brooke S."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"10 months of Betty Rocker!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      10 months of Betty Rocker! I started doing whatever free workout felt good for my mood and I
                      didn't really get a plan for months, I've since done HWD and I'm currently halfway through Abs and
                      Booty.
                    </p>
                    <p className="txt--pink">
                      <strong>- Brooke S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="txt--center txt--15 lh--auto">
            <em>
              * Individual results may vary and may not be typical or representative of what you might expect.{" "}
              <br className="d-none d-lg-block" />
              To learn more about our community’s experiences with our programs look at our Testimonial Support page.
            </em>
          </p>
        </div>
      </section>

      <section className="arrow-down">
        <div className="container pt--40 txt--center pt--xs-0">
          <h2 className="mb--10 txt--uppercase h2--xs-30 lh--xs-1_3">
            START{" "}
            <span className="txt--purple-light">
              home workout
              <br className="d-block d-lg-none" /> domination 2
            </span>{" "}
            TODAY!
          </h2>

          <div className="txt--center d-none d-md-block">
            <img
              loading="lazy"
              className="lazy no-shadow mb--20"
              src="/images/hwd2/hwd2-program.png"
              alt="Program."
              width="1201"
              height="594"
            />
          </div>

          <div className="txt--center d-block d-md-none">
            <img
              loading="lazy"
              className="lazy no-shadow mb--20"
              src="/images/hwd2/hwd2-program-mob.png"
              alt="Program."
              width="1189"
              height="633"
            />
          </div>

          <a href="#start-now" onClick={scrollTo} className="btn btn--xlg txt--28">
            Start Now
          </a>
        </div>
      </section>

      <section className="bg--dark-hwd js-section zindex--5 p--relative" id="included">
        <div className="container">
          <h2 className="txt--white h2--sm mb--50 mb--sm-40 mt--10 lh--xs-1_3 mb--xs-30">
            Your Complete Home Workout Domination 2 <span className="d-inline-block">Program Includes…</span>
          </h2>

          <div className="txt--center">
            <img
              loading="lazy"
              className="lazy no-shadow mb--40"
              src="/images/hwd2/main-workouts.png"
              alt="All Main Workouts."
              width="956"
              height="499"
            />
          </div>

          <div className="row row--border row--border__solid">
            <div className="col-12 col-lg-6 txt--white">
              <h4 className="txt--uppercase txt--center mb--20 txt--white h4--mob mb--xs-15">
                <strong>FOLLOW ALONG STRENGTH TRAINING WORKOUTS</strong>
              </h4>
              <ul className="checkmark-purple-light pb--20">
                <li className="txt--white">
                  Guided strength training workouts to help you sculpt lean muscle and enjoy more capability, endurance
                  and a strong, functional body.
                </li>
                <li className="txt--white">
                  Enjoy building strength and getting shredded with fun workouts that will keep your body progressing
                  forward!
                </li>
              </ul>
            </div>

            <div className="col-12 col-lg-6 txt--white">
              <h4 className="txt--uppercase txt--center mb--20 txt--white h4--mob mb--xs-15">
                <strong>FULL BODY ﹒LOWER BODY ﹒UPPER BODY</strong>
              </h4>
              <ul className="checkmark-purple-light pb--20 pb--xs-0">
                <li className="txt--white">
                  Perfect to follow up the original Home Workout Domination or use completely on its own, this program
                  gets results!
                </li>
                <li className="txt--white">
                  Follow along with upbeat guidance, form tips and motivation from Betty Rocker and the Team Betty
                  Rocker Coaches.
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border row--border__solid">
            <div className="col-12 col-lg-6 txt--center">
              <img
                src="/images/hwd2/includes-1.png"
                alt=""
                width="547"
                height="181"
                className="lazy no-shadow mb--md-15"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white h4--mob mb--xs-15">
                <strong>HIIT/EXPLOSIVE CARDIO WORKOUTS</strong>
              </h4>
              <ul className="checkmark-purple-light">
                <li className="txt--white">
                  Build bone density and muscular strength, up your cardiovascular capacity, save time and maximize your
                  fat burn with these fun sequences!
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border row--border__solid">
            <div className="col-12 col-lg-6 txt--center">
              <img
                src="/images/hwd2/includes-2.png"
                alt=""
                width="547"
                height="178"
                className="lazy no-shadow mb--md-15"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white h4--mob mb--xs-15">
                <strong>abs WORKOUTS</strong>
              </h4>
              <ul className="checkmark-purple-light">
                <li className="txt--white">
                  Strengthen your core with targeted workouts designed to sculpt strong, engaged abdominal muscles that
                  support your back.
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border row--border__solid">
            <div className="col-12 col-lg-6 txt--center">
              <img
                src="/images/hwd2/includes-3.png"
                alt=""
                width="547"
                height="187"
                className="lazy no-shadow mb--md-15"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white h4--mob mb--xs-15">
                <strong>barre WORKOUTS</strong>
              </h4>
              <ul className="checkmark-purple-light">
                <li className="txt--white">
                  Strengthen and lengthen key postural muscles and build better balance with fun barre workouts!
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center mb--70 mb--xs-50">
            <div className="col-12 col-lg-6 txt--center">
              <img
                src="/images/hwd2/includes-4.png"
                alt=""
                width="547"
                height="165"
                className="lazy no-shadow mb--md-15"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white h4--mob mb--xs-15">
                <strong>yoga WORKOUTS</strong>
              </h4>
              <ul className="checkmark-purple-light">
                <li className="txt--white">
                  Stretch and improve flexibility while caring for hard working muscles in athletic yoga workouts.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/*meet your coach*/}
      <section className="arrow-down bg--section js-section bg--hwd2-coaches" id="meet-your-coach">
        <div className="container">
          <h2 className="mb--20 h2--xs-30 lh--xs-1_3">
            Betty Rocker and the Team Betty Rocker coaches <br className="d-none d-lg-block" /> will guide you{" "}
            <span className="d-inline-block">to success!</span>
          </h2>
          <p className="mb--40 txt--center">
            <strong>
              Our certified fitness coaches will keep you motivated and on track to reaching your goals!{" "}
              <br className="d-none d-lg-block" />
              We can’t wait to <span className="d-inline-block">work out with you!</span>
            </strong>
          </p>

          <div className="row align-items-end">
            <div className="col-6 col-lg-3 mb--md-30">
              <div className="img-coaches img-coaches__main">
                <img
                  src="/images/coaches/betty-hwd2.png"
                  alt="Betty Rocker."
                  width="241"
                  height="241"
                  className="lazy no-shadow"
                />
              </div>

              <p>
                <span className="txt--lightgray txt--xxs-12">Head Trainer:</span>
                <br />{" "}
                <strong>
                  The Betty Rocker{" "}
                  <button onClick={() => handleModal("Bree")} aria-label="Open modal about Bree.">
                    <span className="modal-coach__trigger txt--pink">
                      <i className="fa-solid fa-circle-plus" />
                    </span>
                  </button>
                </strong>
              </p>
            </div>

            <div className="col-6 col-lg-3 mb--md-30">
              <div className="img-coaches">
                <img
                  src="/images/coaches/amanda-round.png"
                  alt="Amanda Hill."
                  width="250"
                  height="250"
                  className="lazy no-shadow"
                />
              </div>

              <p>
                <span className="txt--lightgray txt--xs-14 txt--xxs-12">Team Betty Rocker Coach:</span>
                <br />{" "}
                <strong>
                  Amanda Hill{" "}
                  <button onClick={() => handleModal("Amanda")} aria-label="Open modal about Amanda.">
                    <span className="modal-coach__trigger txt--pink">
                      <i className="fa-solid fa-plus-circle" />
                    </span>
                  </button>
                </strong>
              </p>
            </div>

            <div className="col-6 col-lg-3">
              <div className="img-coaches">
                <img
                  src="/images/coaches/nikki-round.png"
                  alt="Nikki Cotton."
                  width="250"
                  height="250"
                  className="lazy no-shadow"
                />
              </div>

              <p>
                <span className="txt--lightgray txt--xs-14 txt--xxs-12">Team Betty Rocker Coach:</span>
                <br />{" "}
                <strong>
                  Nikki Cotton{" "}
                  <button onClick={() => handleModal("Nikki")} aria-label="Open modal about Nikki.">
                    <span className="modal-coach__trigger txt--pink">
                      <i className="fa-solid fa--plus-circle" />
                    </span>
                  </button>
                </strong>
              </p>
            </div>

            <div className="col-6 col-lg-3">
              <div className="img-coaches">
                <img
                  src="/images/coaches/roz-voges.png"
                  alt="Rozaline Voges."
                  width="250"
                  height="250"
                  className="lazy no-shadow"
                />
              </div>

              <p>
                <span className="txt--lightgray txt--xs-14 txt--xxs-12">Team Betty Rocker Coach:</span>
                <br />{" "}
                <strong>
                  Rozaline Voges{" "}
                  <button onClick={() => handleModal("Rozaline")} aria-label="Open modal about Rozaline.">
                    <span className="modal-coach__trigger txt--pink">
                      <i className="fa-solid fa--plus-circle" />
                    </span>
                  </button>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*bonuses*/}
      <section className="container container--md arrow-down">
        <h2 className="h2--sm mb--40 mb--xs-30">
          <span className="txt--purple-light">Special Bonus</span> Gifts To Help You Get Even More
        </h2>
        <div className="card card--tag-sm mb--30 mb--xs-20">
          <div className="card__head card__head--gray">
            <div className="tag tag--purple-light">
              <span className="d-none d-md-inline-block">Bonus</span> #1
            </div>
            <h4>
              <strong>Healthy Eating Jumpstart Guide</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center">
              <div className="col-md-6 mb--sm-30 txt--center">
                <img className="no-shadow lazy" src="/images/hwd2/bonus-1.png" alt="" width="360" height="291" />
              </div>
              <div className="col-md-6">
                <ul className="checkmark-purple-light">
                  <li>
                    Get a jump on <strong>healthy eating</strong> and speed up your <strong>results</strong> with this
                    jumpstart guide
                  </li>
                  <li>
                    Sample <strong>7 day</strong> meal plan
                  </li>
                  <li>
                    <strong>20 healthy recipes</strong> for breakfast, lunch, dinner and snacks!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm mb--30 mb--xs-20">
          <div className="card__head card__head--gray">
            <div className="tag tag--purple-light">
              <span className="d-none d-md-inline-block">Bonus</span> #2
            </div>
            <h4>
              <strong>Workout Calendars</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center">
              <div className="col-md-6 txt--center mb--sm-30">
                <img className="no-shadow lazy" src="/images/hwd2/bonus-2.png" alt="" width="358" height="230" />
              </div>
              <div className="col-md-6">
                <ul className="checkmark-purple-light">
                  <li>Workout calendars to help you stay on track with your plan</li>
                  <li>
                    Short week calendar to help you stay consistent with your progress when life gets extra busy (also
                    great for beginners)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm mb--30 mb--xs-20">
          <div className="card__head card__head--gray">
            <div className="tag tag--purple-light">
              <span className="d-none d-md-inline-block">Bonus</span> #3
            </div>
            <h4>
              <strong>60 Day Coaching and Accountability E-course</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row">
              <div className="col-md-6 txt--center mb--sm-30">
                <img className="no-shadow lazy" src="/images/hwd2/bonus-3.png" alt="" width="367" height="231" />
              </div>
              <div className="col-md-6">
                <ul className="checkmark-purple-light">
                  <li>
                    Personal emails from your coach (me) to <strong>encourage and support</strong> you on your fitness
                    journey!
                  </li>
                  <li>
                    Motivating content each week covering <strong>nutrition, fitness and mindset</strong> to help you
                    stay on track with your plan
                  </li>
                  <li>
                    <strong>Be supported and inspired</strong> as you work your way to great results
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm mb--40 mb--xs-20">
          <div className="card__head card__head--gray">
            <div className="tag tag--purple-light">
              <span className="d-none d-md-inline-block">Bonus</span> #4
            </div>
            <h4>
              <strong>Workout Trackers</strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center">
              <div className="col-md-6 txt--center mb--sm-30">
                <img className="no-shadow lazy" src="/images/hwd2/bonus-4.png" alt="" width="373" height="287" />
              </div>
              <div className="col-md-6">
                <ul className="checkmark-purple-light">
                  <li>
                    <strong>Printable workout tracker</strong> to take with you on the go
                  </li>
                  <li>
                    Conveniently <strong>track your progress</strong> as you workout to see how your strength increases
                  </li>
                  <li>
                    <strong>Motivate yourself</strong> by looking back and see how far you’ve come!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*start now*/}
      <section className="bg--gray-top pb--xs-0 arrow-down" id="start-now">
        <div className="container pt--40 pt--xs-0">
          <h2 className="mb--10 h2--mob">START TODAY!</h2>
          <h2 className="fw--regular txt--center h2--sm h2--xs-32 h2--xxs-32 h2--xxxs-26 mb--40 mb--xs-20 lh--xs-1_3">
            Click “Start Now” begin{" "}
            <strong>
              <span className="txt--purple-light">Home Workout Domination 2</span>
            </strong>{" "}
            today!
          </h2>

          <div className="txt--center">
            <img src="/images/hwd2/hwd2-main.png" alt="" width="902" height="548" className="lazy no-shadow mb--40" />
          </div>

          <div className="container container--md txt--center border-top-1 border-light-gray pt--30">
            <h2 className="fw--regular h2--sm h2--xs-32 h2--xxs-32 h2--xxxs-26 mb--40 lh--xs-1_3 mb--xs-30">
              <span className="d-inline-block">
                Workout Plan{" "}
                <span className="txt--purple-light">
                  <strong>($197 Value)</strong>
                </span>
              </span>
              <span className="d-inline-block mx--5">
                + Bonuses{" "}
                <span className="d-inline-block txt--purple-light">
                  <strong>($217 Value)</strong>
                </span>
              </span>
              <span className="d-inline-block">= $414 Value</span>
            </h2>

            <DigitalProductBuyBox shopifyProduct={product} />

            <img
              className="shadow-0 d-block mx--auto mb--30 mb--xs-10 lazy"
              src="/images/credit-cards.png"
              alt=""
              width="300"
              height="39"
            />

            <p className="txt--14 txt--xs-16 mb--10 mb--xs-20">
              <strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong>
            </p>
            <p className="txt--14 txt--xs-16 mb--xs-0">
              <em>
                You’ll receive INSTANT ACCESS to the Home Workout Domination
                <sup>&reg;</sup> and Bonuses Immediately <br className="d-none d-md-block" />
                After Purchase. This Program is 100% Digital. No Physical Products will be Shipped.
              </em>
            </p>
          </div>
        </div>
      </section>

      {/*guarantee*/}
      <div className="container pt--30 pb--50 pb--xs-30">
        <div className="box-guarantee box-guarantee--purple-light">
          <img src="/images/guarantee.png" alt="" width="239" height="245" className="lazy no-shadow" />
          <div className="row">
            <div className="col-lg-8">
              <h2 className="h2--sm txt--white mb--20 txt--smd-left">60 – Day Money Back GUARANTEE</h2>

              <p className="txt--white lh--auto">
                Take the first step toward improving your health by trying my Home Workout Domination plan for the next
                60 days. If you don’t feel healthier, more energized and simply amazing, you won’t pay. If you’re not
                satisfied, simply contact customer service within 60 days for a full refund.
              </p>

              <p className="txt--white txt--22 fw--black">
                <span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="bg--section js-section py--50 py--xs-30" id="faq">
        <div className="container">
          <h2 className="mb--40 h2--mob mb--xs-20 lh--xs-1_3">
            Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs color="purple-light" productName="hwd2" />
        </div>
      </section>

      {isOpenModal && <ModalCoach handleCloseModal={() => setIsOpenModal(false)} coachName={coachName} />}
    </>
  )
}
export default HomeWorkoutDomination2
