/**
 * Implements testimonial slideUp/Down functionality for the products' testimonials
 * */
const testimonials = product => {
  if (!product) return

  const testimonialToggle = document.querySelectorAll(".js-testimonial-toggle")
  let testimonials = document.querySelectorAll(".testimonial")

  let testimonialOpen = false
  let currentTestimonial
  let otherTestimonials = []

  /*Testimonial toggle*/
  testimonialToggle.forEach(
    testimonialToggleBtn =>
      (testimonialToggleBtn.onclick = e => {
        otherTestimonials = []
        currentTestimonial = e.target.closest(".testimonial")

        for (let item of testimonials) {
          if (item !== currentTestimonial) {
            otherTestimonials.push(item)
          }
        }

        // If the testimonial is fully opened/closed continue
        if (!testimonialOpen) {
          currentTestimonial.classList.toggle("open")
          closeTestimonials(testimonialOpen, otherTestimonials)
          changeTestimonialHeight(testimonialOpen, currentTestimonial)
        }
      }),
  )

  // Get count of testimonial elements
  if (document.querySelectorAll(".testimonial__toggeable").length) {
    setTestimonialHeight(testimonials)
  }
}

const closeTestimonials = (testimonialOpen, el) => {
  let originalTestimonialHeight

  el.forEach(testimonial => {
    originalTestimonialHeight = testimonial.getAttribute("data-original-height")

    if (testimonial.classList.contains("open")) {
      testimonial.classList.remove("open")

      testimonial.animate(
        { height: originalTestimonialHeight + "px" },
        { duration: 300, easing: "ease-in-out", fill: "forwards" },
      )
    }
  })
}

const changeTestimonialHeight = (testimonialOpen, el) => {
  testimonialOpen = true

  let toggleEl = el.querySelector(".testimonial__toggeable")
  let oldHeight = parseInt(el.getAttribute("data-original-height"))
  let toggeableHeight = parseInt(toggleEl.getAttribute("data-toggle-height"))
  let curHeight = toggleEl.offsetHeight
  let newHeight

  let toggleDiff = toggeableHeight - curHeight

  if (el.classList.contains("open")) {
    newHeight = oldHeight + toggleDiff
  } else {
    newHeight = oldHeight
  }

  el.animate({ height: newHeight + "px" }, { duration: 300, easing: "ease-in-out", fill: "forwards" }).onfinish =
    () => {
      testimonialOpen = false
    }
}

const setTestimonialHeight = testimonials => {
  const countTestimonials = testimonials.length

  let testimonialImg, imgWidth, originalHeight
  let toggeable, toggeableWidth, toggeableText, newEl, toggeableHeight

  testimonials.forEach((testimonial, index) => {
    // Reset the set height on element and parent element
    testimonial.style.heigth = "unset"
    testimonial.parentNode.style.height = "unset"

    // Get the testimonial height with paddings, margins and border
    testimonialImg = testimonial.querySelector(".testimonial__img")
    imgWidth = testimonialImg.clientWidth
    testimonialImg.style.height = imgWidth + "px"

    originalHeight = testimonial.offsetHeight

    // Set the original height for the parent element, the one that will hold that space when the element is clicked
    testimonial.parentNode.style.height = originalHeight + "px"

    // Set the original height and z-index for the animation
    testimonial.style.height = originalHeight + "px"
    testimonial.style.zIndex = countTestimonials - index + 10
    testimonial.setAttribute("data-original-height", originalHeight)

    // Get height of toggeable text
    toggeable = testimonial.querySelector(".testimonial__toggeable")
    toggeableWidth = toggeable.offsetWidth
    toggeableText = toggeable.innerHTML

    newEl = document.createElement("DIV")
    newEl.setAttribute("id", "toggleTest")
    newEl.classList.add("hidden-element")
    newEl.style.width = toggeableWidth + "px"
    newEl.innerHTML = toggeableText

    document.body.appendChild(newEl)

    toggeableHeight = newEl.offsetHeight

    // Set data toggle height with the original height of the hidden text
    toggeable.removeAttribute("data-toggle-height")
    toggeable.setAttribute("data-toggle-height", toggeableHeight)

    let dummyEl = document.getElementById("toggleTest")
    dummyEl.parentNode.removeChild(dummyEl)
  })
}

export default testimonials
